import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { 
  Users, 
  Mail, 
  Globe, 
  MessageCircle,
  Search, 
  Filter, 
  AlertCircle,
  Star,
  Zap,
  EyeIcon,
  X,
  FileText // Added for summary icon
} from 'lucide-react';

const DefaultProfileIcon = ({ name }) => {
  // Generate a background color based on the prospect's name
  const getColorFromName = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  // Get initials from name
  const getInitials = (name) => {
    return name 
      ? name.split(' ').map(n => n[0]).slice(0, 2).join('').toUpperCase()
      : 'NA';
  };

  return (
    <div 
      className="w-10 h-10 rounded-full flex items-center justify-center text-white font-bold"
      style={{ backgroundColor: getColorFromName(name || 'Unknown') }}
    >
      {getInitials(name)}
    </div>
  );
};

const ProspectManagement = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [prospects, setProspects] = useState([]);
  const [filteredProspects, setFilteredProspects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [goldenLeadSet, setGoldenLeadSet] = useState([]);
  const [isGoldenLeadModalOpen, setIsGoldenLeadModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProspectTip, setSelectedProspectTip] = useState(null);

  // Fetch Prospects API Call
  const fetchProspects = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/alleads', {
        userId: userInfo._id
      });

      // Log the response data to understand its structure
      console.log('API Response:', response.data);

      // Extract leads from the response
      const prospectData = response.data.leads || [];
      
      setProspects(prospectData);
      setFilteredProspects(prospectData);
    } catch (error) {
      console.error('Failed to fetch prospects', error);
      setError(error.message);
      setProspects([]);
      setFilteredProspects([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo?._id) {
      fetchProspects();
    }
  }, [userInfo]);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    
    const filtered = (prospects || []).filter(
      prospect => 
        prospect.name?.toLowerCase().includes(term) ||
        prospect.company?.toLowerCase().includes(term) ||
        prospect.email?.toLowerCase().includes(term)
    );
    setFilteredProspects(filtered);
  };

  const getValidityColor = (score) => {
    const numScore = parseInt(score);
    if (numScore > 80) return 'bg-green-500/10 text-green-400';
    if (numScore > 60) return 'bg-yellow-500/10 text-yellow-400';
    return 'bg-red-500/10 text-red-400';
  };

  const createGoldenLeadSet = () => {
    const highScoreProspects = (prospects || []).filter(
      prospect => parseInt(prospect.validityscore) > 85
    );
    setGoldenLeadSet(highScoreProspects);
  };

  const handleProspectUpdate = async (prospect, status) => {
    try {
      // Placeholder for update API call
      const updatedProspect = {
        ...prospect,
        responseStatus: status
      };
      
      const updatedProspects = (prospects || []).map(p => 
        p.duserId === prospect.duserId ? updatedProspect : p
      );
      setProspects(updatedProspects);
      setFilteredProspects(updatedProspects);
    } catch (error) {
      console.error('Failed to update prospect', error);
    }
  };

  const syncGoldenLeadSet = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/addp', {
        prospects: goldenLeadSet,
        leadSets: 'Golden Lead Set',
        userId: userInfo._id
      });
      setIsGoldenLeadModalOpen(true);
    } catch (error) {
      console.error('Failed to sync golden lead set', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return (
      <div className="min-h-screen bg-black text-white p-4 flex items-center justify-center">
        <div className="text-center">
          <AlertCircle className="mx-auto w-12 h-12 text-red-500 mb-4" />
          <h2 className="text-xl font-bold text-white mb-2">Error Loading Prospects</h2>
          <p className="text-gray-400 mb-4">{error}</p>
          <button 
            onClick={fetchProspects}
            className="bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded text-white"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-black text-white p-4 flex items-center justify-center">
        <div className="text-center">
          <Users className="mx-auto w-12 h-12 text-blue-500 animate-pulse mb-4" />
          <p className="text-gray-400">Loading prospects...</p>
        </div>
      </div>
    );
  }
  const openAIProspectAnalysis = (prospect) => {
    setSelectedProspectTip(prospect);
  };

  const closeAIProspectAnalysis = () => {
    setSelectedProspectTip(null);
  };
  return (
    <div className="min-h-screen bg-black text-white p-4">
      <div className="container mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-200 flex items-center">
            <Users className="mr-2 text-blue-500" /> Prospect Management
          </h1>
          <div className="flex space-x-2">
            <button 
              onClick={createGoldenLeadSet}
              className="bg-yellow-600 hover:bg-yellow-700 px-3 py-1 rounded text-sm flex items-center"
            >
              <Star className="mr-1 w-4 h-4" /> Create Golden Lead Set
            </button>
            {goldenLeadSet.length > 0 && (
              <button 
                onClick={syncGoldenLeadSet}
                className="bg-green-600 hover:bg-green-700 px-3 py-1 rounded text-sm flex items-center"
              >
                <Zap className="mr-1 w-4 h-4" /> Sync Golden Lead Set
              </button>
            )}
          </div>
        </div>

        {/* Search and Filters */}
        <div className="mb-6 flex space-x-4">
          <div className="relative flex-grow">
            <input 
              type="text"
              placeholder="Search prospects..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full bg-gray-800 text-white px-4 py-2 rounded-lg pl-10"
            />
            <Search className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
          </div>
          <button className="bg-gray-800 hover:bg-gray-700 px-3 py-2 rounded text-sm flex items-center">
            <Filter className="mr-2 w-4 h-4" /> Filters
          </button>
        </div>

        {/* Prospects Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {(filteredProspects || []).map((prospect) => (
          <div 
            key={prospect.duserId || Math.random()} 
            className="bg-gray-900/70 rounded-lg p-4 hover:bg-gray-800 transition-all duration-300"
          >
            <div className="flex justify-between items-start mb-3">
              <div className="flex items-center space-x-3">
                {prospect.profilePicture ? (
                  <img 
                    src={prospect.profilePicture} 
                    alt={prospect.name} 
                    className="w-10 h-10 rounded-full object-cover"
                  />
                ) : (
                  <DefaultProfileIcon name={prospect.name} />
                )}
                <div>
                  <h2 className="text-lg font-semibold">{prospect.name}</h2>
                  <p className="text-sm text-gray-400">{prospect.company}</p>
                </div>
              </div>
              <span 
                className={`
                  px-2 py-1 rounded-full text-xs font-medium
                  ${getValidityColor(prospect.validityscore)}
                `}
              >
                {prospect.validityscore}%
              </span>
            </div>

              <div className="space-y-2 mb-3">
                <div className="flex items-center text-sm">
                  <Mail className="w-4 h-4 mr-2 text-blue-400" />
                  <span className="text-gray-300">{prospect.email}</span>
                </div>
                <div className="flex items-center text-sm">
                  <Globe className="w-4 h-4 mr-2 text-green-400" />
                  <a 
                    href={prospect.website} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-400 hover:underline"
                  >
                    {prospect.website}
                  </a>
                </div>
              </div>
              <div className="bg-gray-800 p-3 rounded-lg relative mb-3">
              <h3 className="text-sm font-medium text-gray-300 mb-2 flex items-center">
                <FileText className="w-4 h-4 mr-2 text-blue-500" />
                Prospect Summary
              </h3>
              <p className="text-xs text-gray-400 line-clamp-3">{prospect.summary || 'No summary available'}</p>
            </div>

              <div className="bg-gray-800 p-3 rounded-lg relative">
              <h3 className="text-sm font-medium text-gray-300 mb-2 flex items-center">
                <AlertCircle className="w-4 h-4 mr-2 text-yellow-500" />
                Conversion Tip
              </h3>
              <p className="text-xs text-gray-400 line-clamp-2">{prospect.tipforconversion}</p>
              <button 
                onClick={() => openAIProspectAnalysis(prospect)}
                className="absolute top-2 right-2 text-blue-400 hover:text-blue-300"
              >
                <EyeIcon className="w-4 h-4" />
              </button>
            </div>
              <div className="mt-4 space-y-2">
                <button 
                  onClick={() => handleProspectUpdate(prospect, 'received_response')}
                  className="w-full bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-xs flex items-center justify-center"
                >
                  <MessageCircle className="mr-1 w-4 h-4" /> Received Response
                </button>
                <button 
                  onClick={() => handleProspectUpdate(prospect, 'revalidate')}
                  className="w-full bg-yellow-600 hover:bg-yellow-700 px-3 py-1 rounded text-xs flex items-center justify-center"
                >
                  <Zap className="mr-1 w-4 h-4" /> No Response & Revalidate
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Empty State */}
        {(!filteredProspects || filteredProspects.length === 0) && (
          <div className="text-center py-10 bg-gray-900/70 rounded-lg">
            <Users className="mx-auto w-12 h-12 text-gray-600 mb-4" />
            <p className="text-gray-400">No prospects found</p>
          </div>
        )}

        {/* Golden Lead Set Success Modal */}
        {isGoldenLeadModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-900 p-6 rounded-lg max-w-sm w-full">
              <div className="text-center">
                <Zap className="mx-auto w-12 h-12 text-green-500 mb-4" />
                <h2 className="text-xl font-bold text-white mb-2">Golden Lead Set Created!</h2>
                <p className="text-gray-400 mb-4">Let's craft our game plan for these top prospects.</p>
                <button 
                  onClick={() => setIsGoldenLeadModalOpen(false)}
                  className="bg-green-600 hover:bg-green-700 px-4 py-2 rounded text-white"
                >
                  Let's Go!
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedProspectTip && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-gray-900 rounded-lg max-w-xl w-full shadow-2xl border border-gray-800">
              {/* Header */}
              <div className="flex justify-between items-center p-4 border-b border-gray-800">
                <h2 className="text-xl font-bold text-white">AI Prospect Analysis</h2>
                <button 
                  onClick={closeAIProspectAnalysis}
                  className="text-gray-400 hover:text-white p-2 rounded-full hover:bg-gray-800 transition-colors"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

            {/* Content */}
            <div className="p-4">
              {/* Prospect Header */}
              <div className="flex items-center mb-4">
                {selectedProspectTip.profilePicture ? (
                  <img 
                    src={selectedProspectTip.profilePicture} 
                    alt={selectedProspectTip.name} 
                    className="w-16 h-16 rounded-full object-cover mr-4"
                  />
                ) : (
                  <DefaultProfileIcon name={selectedProspectTip.name} className="mr-4" />
                )}
                <div>
                  <h3 className="text-lg font-semibold">{selectedProspectTip.name}</h3>
                  <p className="text-sm text-gray-400">{selectedProspectTip.company}</p>
                </div>
              </div>

              {/* Conversion Tip */}
              <div className="bg-gray-800 p-4 rounded-lg mb-4">
                  <h4 className="text-sm font-medium text-gray-300 mb-2 flex items-center">
                    <FileText className="w-4 h-4 mr-2 text-blue-500" />
                    Detailed Prospect Summary
                  </h4>
                  <p className="text-sm text-gray-200">{selectedProspectTip.summary || 'No detailed summary available'}</p>
                </div>

                {/* Conversion Tip */}
                <div className="bg-gray-800 p-4 rounded-lg mb-4">
                  <h4 className="text-sm font-medium text-gray-300 mb-2 flex items-center">
                    <AlertCircle className="w-4 h-4 mr-2 text-yellow-500" />
                    Detailed Conversion Tip
                  </h4>
                  <p className="text-sm text-gray-200">{selectedProspectTip.tipforconversion}</p>
                </div>

              {/* AI Insights */}
              <div className="bg-gray-800 p-4 rounded-lg">
                <h4 className="text-sm font-medium text-gray-300 mb-2">
                  AI-Powered Insights
                </h4>
                <ul className="space-y-2 text-xs text-gray-200">
                  <li>• Recommended approach: Personalized outreach</li>
                  <li>• Communication channel: {selectedProspectTip.preferredChannel || 'Not specified'}</li>
                  <li>• Potential pain points: {selectedProspectTip.painPoints || 'Further investigation needed'}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProspectManagement;
import React, { useState } from 'react';
import { Info ,ArrowRight, Code, Zap, Brain, Terminal, Shield, Sparkles, Globe, ChevronRight, Star, CheckCircle,  Box, Infinity, BrainCircuit, GitBranch, BarChart3 } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import { Calculator, Cpu, Database, DollarSign, LineChart, Save } from 'lucide-react';
import { BarChart2, Activity } from 'lucide-react';
import { Code2, Braces } from 'lucide-react';
import { Server, Clock, Lock, Users } from 'lucide-react';


const DedicatedInstances = () => {
  const [selectedRegion, setSelectedRegion] = useState('us-east');

  const regions = [
    { id: 'us-east', name: 'US East', location: 'Virginia' },
    { id: 'us-west', name: 'US West', location: 'Oregon' },
    { id: 'eu-central', name: 'EU Central', location: 'Frankfurt' },
    { id: 'ap-east', name: 'AP East', location: 'Singapore' }
  ];

  const instanceTypes = [
    {
      name: 'Standard',
      specs: {
        compute: '32 vCPUs',
        memory: '64 GB RAM',
        storage: '500 GB NVMe',
        throughput: '10,000 req/min'
      },
      features: [
        'Dedicated hardware',
        'Standard SLA',
        'Basic monitoring',
        'Email support'
      ],
      price: 2499
    },
    {
      name: 'Enterprise',
      specs: {
        compute: '64 vCPUs',
        memory: '128 GB RAM',
        storage: '1 TB NVMe',
        throughput: '25,000 req/min'
      },
      features: [
        'Isolated hardware',
        'Enterprise SLA',
        'Advanced monitoring',
        '24/7 priority support',
        'Custom fine-tuning'
      ],
      price: 4999,
      recommended: true
    },
    {
      name: 'Ultra',
      specs: {
        compute: '128 vCPUs',
        memory: '256 GB RAM',
        storage: '2 TB NVMe',
        throughput: '50,000 req/min'
      },
      features: [
        'Custom hardware',
        'Ultra SLA',
        'Real-time monitoring',
        'Dedicated support team',
        'Custom fine-tuning',
        'Hardware redundancy'
      ],
      price: 9999
    }
  ];

  const features = [
    { icon: Shield, text: 'SOC2 Type II Certified' },
    { icon: Clock, text: '99.99% Uptime SLA' },
    { icon: Lock, text: 'HIPAA Compliant' },
    { icon: Globe, text: 'Global CDN' }
  ];

  const handleGetStarted = () => {
    window.open('https://forms.gle/WxZiMyfDYrMxQSQA8', '_blank');
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-gray-800 rounded-xl p-4 md:p-8 shadow-2xl w-full max-w-7xl mx-auto">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4 md:gap-6 pb-6 md:pb-8 border-b border-gray-700">
        <div className="flex items-start md:items-center gap-4 w-full md:w-auto">
          <div className="p-3 bg-purple-500/10 rounded-lg shrink-0">
            <Server className="w-6 h-6 text-purple-400" />
          </div>
          <div className="w-full md:w-auto">
            <h2 className="text-xl md:text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              Dedicated Instances
            </h2>
            <div className="flex flex-wrap items-center gap-2 md:gap-4 mt-3">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center gap-2 text-xs md:text-sm text-gray-400 hover:text-purple-400 transition-colors">
                  <feature.icon className="w-4 h-4" />
                  {feature.text}
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-2 md:flex gap-2 w-full md:w-auto">
          {regions.map((region) => (
            <button
              key={region.id}
              onClick={() => setSelectedRegion(region.id)}
              className={`px-3 md:px-4 py-2 md:py-2.5 rounded-lg text-xs md:text-sm font-medium transition-all duration-200 ${
                selectedRegion === region.id
                  ? 'bg-purple-500/20 text-purple-400 border border-purple-500/50 shadow-lg shadow-purple-500/10'
                  : 'bg-gray-800/50 text-gray-400 hover:bg-gray-700/50 hover:text-purple-400'
              }`}
            >
              <span className="block text-xs opacity-75">{region.location}</span>
              {region.name}
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mt-6 md:mt-8">
        {instanceTypes.map((instance) => (
          <div
            key={instance.name}
            className={`relative bg-gray-800/50 backdrop-blur-sm rounded-xl border transition-all duration-300 hover:transform hover:-translate-y-1 hover:shadow-xl ${
              instance.recommended ? 'border-purple-500/50 shadow-lg shadow-purple-500/10' : 'border-gray-700'
            }`}
          >
            {instance.recommended && (
              <div className="absolute -top-3 left-1/2 -translate-x-1/2 px-4 py-1 bg-purple-500 rounded-full text-xs font-medium text-white shadow-lg">
                Recommended
              </div>
            )}
            <div className="p-4 md:p-6">
              <div className="flex items-baseline justify-between mb-6">
                <h3 className="text-lg md:text-xl font-bold text-white">{instance.name}</h3>
                <div className="text-right">
                  <div className="text-xl md:text-2xl font-bold text-white">${instance.price}</div>
                  <div className="text-xs md:text-sm text-gray-400">per month</div>
                </div>
              </div>

              <div className="space-y-6">
                <div className="grid grid-cols-2 gap-3 md:gap-4">
                  {Object.entries(instance.specs).map(([key, value], index) => {
                    const Icon = [Cpu, Database, Server, Zap][index];
                    return (
                      <div key={key} className="flex items-center gap-2 text-xs md:text-sm">
                        <Icon className="w-4 h-4 text-purple-400" />
                        <span className="text-gray-300">{value}</span>
                      </div>
                    );
                  })}
                </div>

                <div className="space-y-3 py-6 border-t border-gray-700">
                  {instance.features.map((feature, index) => (
                    <div key={index} className="flex items-center gap-3 text-xs md:text-sm text-gray-400">
                      <div className="w-1.5 h-1.5 rounded-full bg-purple-400" />
                      {feature}
                    </div>
                  ))}
                </div>

                <button
                  onClick={handleGetStarted}
                  className={`w-full py-3 rounded-lg font-medium transition-all duration-200 flex items-center justify-center ${
                    instance.recommended
                      ? 'bg-purple-500 text-white hover:bg-purple-600 shadow-lg shadow-purple-500/20'
                      : 'bg-gray-700/50 text-gray-300 hover:bg-gray-600/50 hover:text-white'
                  }`}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ApiPricing = () => {
  const models = [
    {
      name: 'EchidnaCode',
      description: 'Optimized for enterprise workloads',
      price: 0.006,
      icon: Server,
      features: [
        'Highest throughput available',
        'Priority queue access',
        'Enterprise support',
        'Custom fine-tuning',
        'Advanced monitoring',
        'Dedicated account manager'
      ],
      recommended: true
    },
    {
      name: 'Echidna4O',
      description: 'Best performance for complex tasks',
      price: 0.005,
      icon: Cpu,
      features: [
        'High throughput',
        'Standard queue access',
        'Business support',
        'Basic monitoring',
        'API documentation'
      ]
    },
    {
      name: 'EchidnaLite',
      description: 'Cost-effective for basic operations',
      price: 0.004,
      icon: Zap,
      features: [
        'Standard throughput',
        'Shared queue access',
        'Community support',
        'Basic monitoring',
        'Getting started guides'
      ]
    }
  ];

  const features = [
    { icon: Shield, text: 'Enterprise-grade Security' },
    { icon: Clock, text: '99.9% Availability' },
    { icon: Lock, text: 'Data Privacy Guaranteed' },
    { icon: Globe, text: 'Global API Endpoints' }
  ];

  const handleGetStarted = () => {
    window.open('https://forms.gle/WxZiMyfDYrMxQSQA8', '_blank');
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-gray-800 rounded-xl p-4 md:p-8 shadow-2xl">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4 md:gap-6 pb-4 md:pb-8 border-b border-gray-700">
        <div className="flex items-start gap-4">
          <div className="p-2 md:p-3 bg-purple-500/10 rounded-lg">
            <Cpu className="w-5 h-5 md:w-6 md:h-6 text-purple-400" />
          </div>
          <div>
            <h2 className="text-xl md:text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              API Pricing
            </h2>
            <p className="text-sm md:text-base text-gray-400 mt-1">Pay only for what you use, billed per 1,000 tokens</p>
            <div className="flex flex-wrap items-center gap-2 md:gap-4 mt-3">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center gap-1.5 md:gap-2 text-xs md:text-sm text-gray-400 hover:text-purple-400 transition-colors">
                  <feature.icon className="w-3 h-3 md:w-4 md:h-4" />
                  {feature.text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mt-6 md:mt-8">
        {models.map((model) => {
          const ModelIcon = model.icon;
          return (
            <div
              key={model.name}
              className={`relative bg-gray-800/50 backdrop-blur-sm rounded-xl border transition-all duration-300 hover:transform hover:-translate-y-1 hover:shadow-xl ${
                model.recommended ? 'border-purple-500/50 shadow-lg shadow-purple-500/10' : 'border-gray-700'
              }`}
            >
              {model.recommended && (
                <div className="absolute -top-3 left-1/2 -translate-x-1/2 px-4 py-1 bg-purple-500 rounded-full text-xs font-medium text-white shadow-lg">
                  Most Popular
                </div>
              )}
              <div className="p-4 md:p-6">
                <div className="flex items-start gap-3 md:gap-4 mb-4 md:mb-6">
                  <div className="p-2 bg-purple-500/10 rounded-lg">
                    <ModelIcon className="w-4 h-4 md:w-5 md:h-5 text-purple-400" />
                  </div>
                  <div>
                    <h3 className="text-lg md:text-xl font-bold text-white">{model.name}</h3>
                    <p className="text-xs md:text-sm text-gray-400 mt-1">{model.description}</p>
                  </div>
                </div>

                <div className="flex items-baseline gap-1 mb-4 md:mb-6">
                  <span className="text-2xl md:text-3xl font-bold text-white">${model.price}</span>
                  <span className="text-sm md:text-base text-gray-400">/1k tokens</span>
                </div>

                <div className="space-y-2 md:space-y-3 py-4 md:py-6 border-t border-gray-700">
                  {model.features.map((feature, index) => (
                    <div key={index} className="flex items-center gap-2 md:gap-3 text-xs md:text-sm text-gray-400">
                      <div className="w-1.5 h-1.5 rounded-full bg-purple-400 flex-shrink-0" />
                      {feature}
                    </div>
                  ))}
                </div>

                <button
                  onClick={handleGetStarted}
                  className={`w-full py-2.5 md:py-3 rounded-lg font-medium text-center inline-flex items-center justify-center transition-all duration-200 ${
                    model.recommended
                      ? 'bg-purple-500 text-white hover:bg-purple-600 shadow-lg shadow-purple-500/20'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white'
                  }`}
                >
                  Get Started
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-6 md:mt-8 p-3 md:p-4 bg-gray-800/30 rounded-lg border border-gray-700">
        <p className="text-xs md:text-sm text-gray-400 text-center">
          All prices are in USD. Enterprise customers with high-volume needs can contact sales for custom pricing.
        </p>
      </div>
    </div>
  );
};

const CodeExamples = () => {
  const [selectedLang, setSelectedLang] = useState('python');

  const languages = [
    { id: 'python', name: 'Python', icon: Terminal },
    { id: 'javascript', name: 'JavaScript', icon: Globe },
    { id: 'cpp', name: 'C++', icon: Cpu },
    { id: 'curl', name: 'cURL', icon: Box }
  ];

  const codeExamples = {
    python: `import echidna

# Initialize the client
client = echidna.Client(api_key="your_api_key")

# Generate text
response = client.generate(
    model="echidna-code",
    prompt="Write a function to calculate fibonacci numbers",
    max_tokens=150,
    temperature=0.7
)

# Stream responses
for chunk in client.generate.stream(
    model="echidna-code",
    prompt="Explain quantum computing",
    max_tokens=100
):
    print(chunk.text, end="")

# Function calling
response = client.generate(
    model="echidna-code",
    prompt="What's the weather in Paris?",
    functions=[{
        "name": "get_weather",
        "parameters": {"location": "string", "unit": "string"}
    }]
)`,
    javascript: `import { EchidnaAPI } from '@echidna/api';

// Initialize the client
const echidna = new EchidnaAPI({
  apiKey: 'your_api_key'
});

// Generate text
const response = await echidna.generate({
  model: 'echidna-code',
  prompt: 'Write a function to calculate fibonacci numbers',
  maxTokens: 150,
  temperature: 0.7
});

// Stream responses
for await (const chunk of echidna.generateStream({
  model: 'echidna-code',
  prompt: 'Explain quantum computing',
  maxTokens: 100
})) {
  process.stdout.write(chunk.text);
}

// Function calling
const response = await echidna.generate({
  model: 'echidna-code',
  prompt: "What's the weather in Paris?",
  functions: [{
    name: 'get_weather',
    parameters: { location: 'string', unit: 'string' }
  }]
});`,
    cpp: `#include <echidna/client.hpp>

int main() {
    // Initialize the client
    echidna::Client client("your_api_key");
    
    // Generate text
    auto response = client.generate(
        echidna::GenerateParams()
            .setModel("echidna-code")
            .setPrompt("Write a function to calculate fibonacci numbers")
            .setMaxTokens(150)
            .setTemperature(0.7)
    );

    // Stream responses
    client.generateStream(
        echidna::GenerateParams()
            .setModel("echidna-code")
            .setPrompt("Explain quantum computing")
            .setMaxTokens(100),
        [](const auto& chunk) {
            std::cout << chunk.text;
        }
    );

    // Function calling
    auto response = client.generate(
        echidna::GenerateParams()
            .setModel("echidna-code")
            .setPrompt("What's the weather in Paris?")
            .setFunctions({
                {
                    "get_weather",
                    {{"location", "string"}, {"unit", "string"}}
                }
            })
    );
}`,
    curl: `# Generate text
curl https://api.echidna.ai/v1/generate \\
  -H "Authorization: Bearer your_api_key" \\
  -H "Content-Type: application/json" \\
  -d '{
    "model": "echidna-code",
    "prompt": "Write a function to calculate fibonacci numbers",
    "max_tokens": 150,
    "temperature": 0.7
  }'

# Stream responses
curl https://api.echidna.ai/v1/generate/stream \\
  -H "Authorization: Bearer your_api_key" \\
  -H "Content-Type: application/json" \\
  -d '{
    "model": "echidna-code",
    "prompt": "Explain quantum computing",
    "max_tokens": 100
  }'

# Function calling
curl https://api.echidna.ai/v1/generate \\
  -H "Authorization: Bearer your_api_key" \\
  -H "Content-Type: application/json" \\
  -d '{
    "model": "echidna-code",
    "prompt": "What'\''s the weather in Paris?",
    "functions": [{
      "name": "get_weather",
      "parameters": {
        "location": "string",
        "unit": "string"
      }
    }]
  }'`
  };

  const features = [
    {
      title: 'Simple Integration',
      description: 'Just a few lines of code to get started with our API'
    },
    {
      title: 'Multiple Languages',
      description: 'Native SDKs for all major programming languages'
    },
    {
      title: 'Streaming Support',
      description: 'Real-time streaming responses for interactive applications'
    },
    {
      title: 'Function Calling',
      description: 'Built-in support for function calling and tool use'
    }
  ];

  return (
    <div className="bg-gray-900 rounded-lg p-4 md:p-8 border border-gray-800">
      <div className="border-b border-gray-800 pb-4 md:pb-6 mb-4 md:mb-6">
        <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
          <div className="flex items-center gap-3">
            <Code2 className="w-5 h-5 md:w-6 md:h-6 text-purple-400" />
            <h2 className="text-xl md:text-2xl font-bold text-white">API Integration</h2>
          </div>
          <div className="flex flex-wrap gap-2">
            {languages.map(({ id, name, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setSelectedLang(id)}
                className={`flex-1 md:flex-none px-3 md:px-4 py-2 rounded-lg flex items-center justify-center gap-2 text-sm md:text-base ${
                  selectedLang === id
                    ? 'bg-gray-800 text-purple-400 border-2 border-purple-500'
                    : 'bg-gray-800/50 text-gray-400 border border-gray-700'
                }`}
              >
                <Icon className="w-4 h-4" />
                <span className="hidden md:inline">{name}</span>
                <span className="md:hidden">{name.slice(0, 3)}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col md:grid md:grid-cols-3 gap-4 md:gap-8">
        <div className="order-2 md:order-1 md:col-span-2 bg-gray-800/50 rounded-lg p-4 md:p-6">
          <div className="overflow-x-auto">
            <pre className="text-xs md:text-sm font-mono text-gray-300 whitespace-pre-wrap min-w-[300px]">
              {codeExamples[selectedLang]}
            </pre>
          </div>
        </div>

        <div className="order-1 md:order-2 flex flex-row md:flex-col gap-4 overflow-x-auto md:overflow-visible md:space-y-6 md:space-x-0 pb-2 md:pb-0">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex-none w-64 md:w-auto bg-gray-800/50 p-4 md:p-6 rounded-lg border border-gray-700"
            >
              <h3 className="text-base md:text-lg font-medium text-white mb-2">{feature.title}</h3>
              <p className="text-gray-400 text-xs md:text-sm">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


const performanceMatrix = [
  { metric: 'Code Generation', EchidnaCode: 99, Echidna4O: 95, EchidnaLite: 85, GPT4o: 92, Claude: 90, GPT35: 82 },
  { metric: 'Response Speed', EchidnaCode: 98, Echidna4O: 97, EchidnaLite: 88, GPT4o: 90, Claude: 89, GPT35: 85 },
  { metric: 'Accuracy', EchidnaCode: 97, Echidna4O: 96, EchidnaLite: 86, GPT4o: 93, Claude: 91, GPT35: 84 },
  { metric: 'Context Window', EchidnaCode: 99, Echidna4O: 98, EchidnaLite: 87, GPT4o: 91, Claude: 90, GPT35: 83 },
  { metric: 'Fine-tuning', EchidnaCode: 98, Echidna4O: 97, EchidnaLite: 89, GPT4o: 92, Claude: 88, GPT35: 86 }
];

const radarData = [
  { subject: 'Speed', EchidnaCode: 99, Competitor: 90 },
  { subject: 'Accuracy', EchidnaCode: 98, Competitor: 92 },
  { subject: 'Scalability', EchidnaCode: 97, Competitor: 89 },
  { subject: 'Cost Efficiency', EchidnaCode: 99, Competitor: 85 },
  { subject: 'API Integration', EchidnaCode: 98, Competitor: 91 }
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-900 border border-gray-700 rounded-lg p-4">
        <p className="text-gray-300 mb-2">{label}</p>
        {payload.map((entry, index) => (
          <div key={index} className="flex items-center gap-2">
            <div
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: entry.fill }}
            />
            <span className="text-gray-400">{entry.name}:</span>
            <span className="text-white font-mono">{entry.value}</span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const AdvancedPerformanceMatrix = () => {
  const [selectedModel, setSelectedModel] = useState('all');

  const models = [
    { id: 'all', name: 'All' },
    { id: 'echidna', name: 'Echidna' },
    { id: 'competitors', name: 'Competitors' }
  ];

  const getFilteredData = () => {
    if (selectedModel === 'all') return performanceMatrix;
    if (selectedModel === 'echidna') {
      return performanceMatrix.map(item => ({
        metric: item.metric,
        EchidnaCode: item.EchidnaCode,
        Echidna4O: item.Echidna4O,
        EchidnaLite: item.EchidnaLite
      }));
    }
    return performanceMatrix.map(item => ({
      metric: item.metric,
      GPT4: item.GPT4o,
      Claude: item.Claude,
      GPT35: item.GPT35
    }));
  };

  return (
    <div className="bg-gray-900 rounded-lg p-4 md:p-8 border border-gray-800">
      <div className="border-b border-gray-800 pb-4 md:pb-6 mb-4 md:mb-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="flex items-center gap-3">
            <Activity className="w-5 h-5 md:w-6 md:h-6 text-purple-400" />
            <h2 className="text-xl md:text-2xl font-bold text-white">Performance Analysis</h2>
          </div>
          <div className="flex flex-wrap gap-2">
            {models.map((model) => (
              <button
                key={model.id}
                onClick={() => setSelectedModel(model.id)}
                className={`px-3 md:px-4 py-1.5 md:py-2 text-sm md:text-base rounded-lg flex-1 md:flex-none ${
                  selectedModel === model.id
                    ? 'bg-gray-800 text-purple-400 border-2 border-purple-500'
                    : 'bg-gray-800/50 text-gray-400 border border-gray-700'
                }`}
              >
                {model.name}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 md:gap-8">
        <div className="bg-gray-800/50 rounded-lg p-4 md:p-6">
          <div className="flex items-center gap-2 mb-4">
            <BarChart2 className="w-4 h-4 text-purple-400" />
            <h3 className="text-base md:text-lg font-medium text-white">Performance Metrics</h3>
          </div>
          <div className="h-64 md:h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart 
                data={getFilteredData()} 
                margin={{ top: 10, right: 10, left: -20, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis 
                  dataKey="metric" 
                  stroke="#9CA3AF"
                  tick={{ fontSize: 12 }}
                  angle={-45}
                  textAnchor="end"
                  interval={0}
                />
                <YAxis 
                  stroke="#9CA3AF" 
                  domain={[80, 100]}
                  tick={{ fontSize: 12 }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend wrapperStyle={{ fontSize: '12px' }} />
                <Bar dataKey="EchidnaCode" fill="#A855F7" radius={[4, 4, 0, 0]} />
                <Bar dataKey="Echidna4O" fill="#8B5CF6" radius={[4, 4, 0, 0]} />
                <Bar dataKey="EchidnaLite" fill="#7C3AED" radius={[4, 4, 0, 0]} />
                <Bar dataKey="GPT4o" fill="#6366F1" radius={[4, 4, 0, 0]} />
                <Bar dataKey="Claude" fill="#4F46E5" radius={[4, 4, 0, 0]} />
                <Bar dataKey="GPT35" fill="#4338CA" radius={[4, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-gray-800/50 rounded-lg p-4 md:p-6">
          <div className="flex items-center gap-2 mb-4">
            <Activity className="w-4 h-4 text-purple-400" />
            <h3 className="text-base md:text-lg font-medium text-white">Competitive Analysis</h3>
          </div>
          <div className="h-64 md:h-80">
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart data={radarData}>
                <PolarGrid stroke="#374151" />
                <PolarAngleAxis 
                  dataKey="subject" 
                  stroke="#9CA3AF"
                  tick={{ fontSize: 12 }}
                />
                <PolarRadiusAxis 
                  stroke="#9CA3AF" 
                  angle={90} 
                  domain={[0, 100]}
                  tick={{ fontSize: 12 }}
                />
                <Radar
                  name="EchidnaCode"
                  dataKey="EchidnaCode"
                  stroke="#A855F7"
                  fill="#A855F7"
                  fillOpacity={0.3}
                />
                <Radar
                  name="Competitor Avg"
                  dataKey="Competitor"
                  stroke="#6366F1"
                  fill="#6366F1"
                  fillOpacity={0.3}
                />
                <Legend wrapperStyle={{ fontSize: '12px' }} />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};


const ComprehensiveCostCalculator = () => {
  const [tokens, setTokens] = useState(1000000);
  const [selectedModel, setSelectedModel] = useState('EchidnaCode');

  const prices = {
    EchidnaCode: 0.006,
    Echidna4O: 0.005,
    EchidnaLite: 0.004,
    GPT4: 0.009,
    Claude: 0.008,
    GPT35: 0.006
  };

  const modelInfo = {
    EchidnaCode: 'Optimized for enterprise workloads',
    Echidna4O: 'Best performance for complex tasks',
    EchidnaLite: 'Cost-effective for basic operations',
    GPT4: 'OpenAI flagship model',
    Claude: 'Anthropic enterprise model',
    GPT35: 'Balanced performance model'
  };

  const calculateCost = (price) => ((tokens / 1000) * price).toFixed(2);
  const calculateSavings = (competitorPrice) =>
    ((tokens / 1000) * (competitorPrice - prices[selectedModel])).toFixed(2);
  
  const monthlyTokensInMillions = (tokens / 1000000).toFixed(2);

  return (
    <div className="bg-gray-900 rounded-lg p-4 md:p-8 border border-gray-800">
      <div className="border-b border-gray-800 pb-4 md:pb-6 mb-4 md:mb-6">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-2">
          <div className="flex items-center gap-3">
            <Calculator className="w-5 h-5 md:w-6 md:h-6 text-purple-400" />
            <h2 className="text-xl md:text-2xl font-bold text-white">Enterprise Cost Calculator</h2>
          </div>
          <div className="text-sm text-gray-400">
            <span className="font-mono">{monthlyTokensInMillions}M</span> tokens/month
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-3 mb-6 md:mb-8">
        {Object.keys(prices).map((model) => (
          <div
            key={model}
            onClick={() => setSelectedModel(model)}
            className={`p-3 md:p-4 rounded-lg cursor-pointer ${
              selectedModel === model
                ? 'bg-gray-800 border-2 border-purple-500'
                : 'bg-gray-800/50 border border-gray-700'
            }`}
          >
            <div className="flex items-center gap-2 mb-1">
              <Cpu className="w-4 h-4 text-purple-400" />
              <div className="text-white font-medium text-sm md:text-base">{model}</div>
            </div>
            <div className="text-gray-400 text-xs md:text-sm mb-1 md:mb-2">${prices[model]}/1k tokens</div>
            <div className="text-gray-500 text-xs hidden md:block">{modelInfo[model]}</div>
          </div>
        ))}
      </div>

      <div className="space-y-4 md:space-y-6">
        <div className="bg-gray-800 rounded-lg p-4 md:p-6">
          <div className="flex flex-col md:flex-row justify-between md:items-center gap-2 md:gap-0 mb-4">
            <div className="flex items-center gap-2">
              <Database className="w-4 h-4 text-purple-400" />
              <label className="text-gray-400 text-sm md:text-base">Token Usage Range</label>
            </div>
            <div className="flex items-center gap-2 bg-gray-900 px-3 py-1 rounded">
              <span className="text-purple-400 font-mono text-xs md:text-sm">{tokens.toLocaleString()}</span>
            </div>
          </div>
          <input
            type="range"
            min="100000"
            max="10000000"
            value={tokens}
            onChange={(e) => setTokens(Number(e.target.value))}
            className="w-full h-1 bg-gray-700 rounded-full appearance-none cursor-pointer"
          />
          <div className="flex justify-between mt-2">
            <span className="text-xs text-gray-500">100K</span>
            <span className="text-xs text-gray-500">10M</span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          <div className="bg-gray-800 p-4 md:p-6 rounded-lg">
            <div className="flex items-center gap-2 text-gray-400 mb-2">
              <LineChart className="w-4 h-4" />
              <span className="text-sm md:text-base">Monthly Cost</span>
            </div>
            <div className="text-xl md:text-2xl font-bold text-white font-mono">
              ${calculateCost(prices[selectedModel])}
            </div>
            <div className="text-gray-500 text-xs md:text-sm mt-1">
              At ${prices[selectedModel]}/1k tokens
            </div>
          </div>
          
          <div className="bg-gray-800 p-4 md:p-6 rounded-lg">
            <div className="flex items-center gap-2 text-gray-400 mb-2">
              <DollarSign className="w-4 h-4" />
              <span className="text-sm md:text-base">GPT-4 Equivalent</span>
            </div>
            <div className="text-xl md:text-2xl font-bold text-white font-mono">
              ${calculateCost(prices.GPT4)}
            </div>
            <div className="text-gray-500 text-xs md:text-sm mt-1">
              At ${prices.GPT4}/1k tokens
            </div>
          </div>
          
          <div className="bg-gray-800 p-4 md:p-6 rounded-lg border-l-4 border-purple-500">
            <div className="flex items-center gap-2 text-purple-400 mb-2">
              <Save className="w-4 h-4" />
              <span className="text-sm md:text-base">Cost Savings</span>
            </div>
            <div className="text-xl md:text-2xl font-bold text-green-400 font-mono">
              ${calculateSavings(prices.GPT4)}
            </div>
            <div className="text-gray-500 text-xs md:text-sm mt-1">
              vs. GPT-4 pricing
            </div>
          </div>
        </div>

        <div className="bg-purple-900/20 rounded-lg p-3 md:p-4">
          <div className="flex items-start gap-2">
            <Info className="w-4 h-4 text-purple-400 mt-1" />
            <div>
              <div className="text-purple-400 text-xs md:text-sm font-medium mb-1">Usage Insights</div>
              <div className="text-gray-400 text-xs md:text-sm">
                Current selection ({selectedModel}) would process {monthlyTokensInMillions}M tokens per month, 
                suitable for {monthlyTokensInMillions < 1 ? 'small-scale' : monthlyTokensInMillions < 5 ? 'medium-scale' : 'large-scale'} enterprise deployments.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const Testimonials = () => {
  const testimonials = [
    {
      quote: "Echidna Code has revolutionized our development pipeline. We're seeing 40% faster deployment cycles.",
      author: "Sarah Chen",
      role: "CTO, TechGiant Inc.",
      avatar: "SC"
    },
    {
      quote: "The cost savings and performance improvements with Echidna 4O have been game-changing for our AI initiatives.",
      author: "Michael Rodriguez",
      role: "AI Director, Enterprise Solutions",
      avatar: "MR"
    },
    {
      quote: "Even Echidna Lite outperforms most competitors' premium offerings. Incredible value proposition.",
      author: "David Park",
      role: "Lead Engineer, StartupX",
      avatar: "DP"
    }
  ];

  return (
    <div className="grid md:grid-cols-3 gap-8">
      {testimonials.map((testimonial, idx) => (
        <div key={idx} className="bg-gray-900/50 backdrop-blur-xl p-8 rounded-lg border border-gray-800 hover:border-purple-500/50 transition-all">
          <div className="flex gap-4 mb-6">
            {[...Array(5)].map((_, i) => (
              <Star key={i} className="h-5 w-5 text-purple-400 fill-current" />
            ))}
          </div>
          <p className="text-gray-300 mb-6 text-lg">{testimonial.quote}</p>
          <div className="flex items-center gap-4">
            <div className="w-12 h-12 rounded-full bg-purple-500/20 flex items-center justify-center text-purple-400 font-medium">
              {testimonial.avatar}
            </div>
            <div>
              <div className="text-white font-medium">{testimonial.author}</div>
              <div className="text-gray-400">{testimonial.role}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};


const CTASection = () => {
  const handleCTAClick = () => {
    window.open('https://forms.gle/WxZiMyfDYrMxQSQA8', '_blank');
  };

  return (
    <div className="relative overflow-hidden bg-gradient-to-r from-purple-900/20 to-cyan-900/20 rounded-lg p-8 md:p-12 border border-purple-500/20">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,_rgba(147,51,234,0.1),transparent_60%)]" />
      
      {/* Floating elements for visual interest */}
      <div className="absolute top-0 right-0 w-64 h-64 bg-purple-500/5 rounded-full blur-3xl" />
      <div className="absolute bottom-0 left-0 w-64 h-64 bg-cyan-500/5 rounded-full blur-3xl" />
      
      <div className="relative z-10 text-center max-w-3xl mx-auto">
        <div className="flex items-center justify-center gap-2 mb-6">
          <div className="px-4 py-1 bg-purple-500/10 rounded-full border border-purple-500/20">
            <div className="flex items-center gap-2 text-purple-400 text-sm font-medium">
              <Sparkles className="w-4 h-4" />
              Limited Access Available
            </div>
          </div>
        </div>
        
        <h2 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-white via-purple-100 to-cyan-100 bg-clip-text text-transparent mb-6">
          Join the Elite Circle of AI Innovation
        </h2>
        
        <p className="text-gray-300 text-base md:text-lg mb-8">
          Experience unprecedented AI performance trusted by industry pioneers. Our waitlist is growing rapidly – secure your position among leading enterprises transforming their AI infrastructure.
        </p>
        
        <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-8">
          <div className="flex items-center gap-2 text-gray-400">
            <Users className="w-4 h-4" />
            <span className="text-sm">93% capacity reached</span>
          </div>
          <div className="flex items-center gap-2 text-gray-400">
            <Clock className="w-4 h-4" />
            <span className="text-sm">Limited slots remaining</span>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center gap-4">
         
          <button 
            onClick={handleCTAClick}
            className="px-8 py-4 bg-gray-900 rounded-lg font-medium text-white hover:bg-gray-800 transition-all flex items-center justify-center"
          >
            Join Waitlist
          </button>
        </div>
      </div>
    </div>
  );
};

const Navbar = () => (
  <nav className="fixed w-full z-50 bg-black/50 backdrop-blur-xl border-b border-gray-800">
    <div className="max-w-7xl mx-auto px-4 h-20 flex items-center justify-between">
      <div className="flex items-center gap-8">
        <div className="text-2xl font-bold text-white">Echidna AI</div>
      
      </div>
      <div className="flex items-center gap-4">
       <a href='https://forms.gle/WxZiMyfDYrMxQSQA8'> 
        <button className="bg-purple-600 px-4 py-2 rounded-lg text-white hover:bg-purple-500 transition-colors">
          Apply
        </button>
        </a>
      </div>
    </div>
  </nav>
);

const Hero = () => (
  <div className="relative min-h-screen bg-black border-b border-gray-800 flex items-center">
    <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,_rgba(147,51,234,0.1),transparent_60%),radial-gradient(circle_at_70%_70%,_rgba(6,182,212,0.1),transparent_60%)]" />
    <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 py-16 sm:py-32">
      <div className="max-w-3xl">
        <div className="inline-flex items-center px-3 py-1 rounded-full bg-purple-500/10 border border-purple-500/20 text-purple-400 text-sm mb-6 sm:mb-8">
          <Sparkles className="h-4 w-4 mr-2" />
          <span className="hidden sm:inline">Outperforming Competitors at 50% of the Cost</span>
          <span className="sm:hidden">50% Lower Cost</span>
        </div>
        
        <h1 className="text-4xl sm:text-6xl md:text-7xl font-bold mb-6 sm:mb-8 leading-tight">
          <span className="text-white">The Next</span>
          <br />
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-cyan-400 to-purple-400">
            Generation of AI
          </span>
        </h1>
        
        <p className="text-gray-400 text-lg sm:text-xl mb-8 sm:mb-12 leading-relaxed max-w-2xl">
          Experience unmatched performance with our suite of AI models. 
          Built for enterprises, optimized for developers, and priced for scale.
        </p>
        
        <div className="flex justify-center sm:justify-start">
          <a 
            href="https://forms.gle/WxZiMyfDYrMxQSQA8"
            target="_blank"
            rel="noopener noreferrer"
            className="group w-full sm:w-auto px-6 sm:px-8 py-4 bg-purple-600 rounded-lg font-medium text-white hover:bg-purple-500 transition-all flex items-center justify-center"
          >
            Apply Now
            <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

const Stats = () => {
  const stats = [
    { icon: Cpu, label: "Response Time", value: "0.1ms" },
    { icon: Brain, label: "Accuracy Rate", value: "99.8%" },
    { icon: Shield, label: "Uptime SLA", value: "99.99%" },
    { icon: BarChart3, label: "Cost Savings", value: "50%" }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 -mt-8 sm:-mt-16 lg:-mt-20 relative z-20">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 lg:gap-8">
        {stats.map((stat, idx) => (
          <div 
            key={idx} 
            className="bg-gray-900/50 backdrop-blur-xl rounded-lg p-4 sm:p-6 border border-gray-800 hover:bg-gray-900/70 transition-all duration-300"
          >
            <stat.icon className="h-6 w-6 text-purple-400 mb-3 sm:mb-4" />
            <div className="text-2xl sm:text-3xl font-bold text-white mb-1 sm:mb-2">
              {stat.value}
            </div>
            <div className="text-sm sm:text-base text-gray-400">
              {stat.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Features = () => (
  <div className="py-32 border-b border-gray-800">
    <div className="max-w-7xl mx-auto px-4">
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold text-white mb-4">Why Choose Echidna AI?</h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Our suite of AI models delivers unmatched performance, reliability, and cost-effectiveness.
        </p>
      </div>
      <div className="grid md:grid-cols-3 gap-8">
        {[
          {
            icon: BrainCircuit,
            title: "Advanced Architecture",
            description: "Built on cutting-edge transformer architecture with optimized attention mechanisms."
          },
          {
            icon: Infinity,
            title: "Infinite Scalability",
            description: "Horizontal scaling with zero downtime and consistent performance."
          },
          {
            icon: Shield,
            title: "Enterprise Security",
            description: "SOC2 Type II certified with end-to-end encryption and data isolation."
          },
          {
            icon: GitBranch,
            title: "Version Control",
            description: "Full model versioning with rollback capabilities and A/B testing."
          },
          {
            icon: Globe,
            title: "Global Edge Network",
            description: "Deployed across 35+ regions for minimal latency worldwide."
          },
          {
            icon: Terminal,
            title: "Developer First",
            description: "Comprehensive SDKs, detailed documentation, and dedicated support."
          }
        ].map((feature, idx) => (
          <div key={idx} className="bg-gray-900/50 backdrop-blur-xl p-8 rounded-lg border border-gray-800 hover:border-purple-500/50 transition-all">
            <feature.icon className="h-8 w-8 text-purple-400 mb-4" />
            <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
            <p className="text-gray-400">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Footer = () => (
  <footer className="bg-black border-t border-gray-800 py-16">
    <div className="max-w-7xl mx-auto px-4">
      <div className="grid md:grid-cols-4 gap-8 mb-8">
        <div>
          <div className="text-2xl font-bold text-white mb-4">Echidna AI</div>
          <p className="text-gray-400">Next-generation AI models for enterprise and developers.</p>
        </div>
        {[
          {
            title: "Product",
            links: ["Models", "Pricing", "Documentation", "API Reference"]
          },
          {
            title: "Company",
            links: ["About", "Blog", "Careers", "Contact"]
          },
          {
            title: "Resources",
            links: ["Community", "Support", "Status", "Terms"]
          }
        ].map((column, idx) => (
          <div key={idx}>
            <div className="text-white font-medium mb-4">{column.title}</div>
            <ul className="space-y-2">
              {column.links.map((link, linkIdx) => (
                <li key={linkIdx}>
                  <a href="#" className="text-gray-400 hover:text-white transition-colors">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="pt-8 border-t border-gray-800 text-gray-400">
        © 2025 Echidna AI. All rights reserved.
      </div>
    </div>
  </footer>
);

const RLandingPage = () => (
  <div className="min-h-screen bg-black text-white">
    <Navbar />
    <Hero />
    <Stats />
    <Features />
    
    <div className="py-32 border-b border-gray-800">
      <div className="max-w-7xl mx-auto px-4 space-y-32">
        <AdvancedPerformanceMatrix />
        <CodeExamples />
        
        <ComprehensiveCostCalculator />
        <ApiPricing />
        <DedicatedInstances />
        <CTASection />
      </div>
    </div>
    
   
  </div>
);

export default RLandingPage;
import React from 'react';
import { Users, Mail, CheckCircle, Settings, Database, BarChart2, Layout } from 'lucide-react';
import logo from './logo.png';

const FeatureCard = ({ icon: Icon, text, description, link }) => (
  <a 
    href={link}
    className="block bg-white p-6 rounded-xl border border-gray-200 hover:shadow-lg transition-all duration-300 no-underline"
  >
    <div className="flex items-center justify-between mb-4">
      <div className="h-12 w-12 bg-blue-50 rounded-lg flex items-center justify-center group-hover:bg-blue-500 transition-colors duration-300">
        <Icon size={24} className="text-blue-500" />
      </div>
      <div className="bg-blue-50 px-3 py-1 rounded-full text-blue-600 text-sm font-medium">
        Navigate
      </div>
    </div>
    <h3 className="text-lg font-semibold mb-2 text-gray-900">{text}</h3>
    <p className="text-gray-500 text-sm">{description}</p>
  </a>
);

const EchidnaDash = () => {
  const features = [
    {
      icon: Users,
      text: "Add Prospect",
      description: "Create and manage new prospect profiles",
      link: "/echidnamail/prospects"
    },
    {
      icon: Mail,
      text: "New Campaign",
      description: "Start a new marketing campaign",
      link: "/new-campaign"
    },
    {
      icon: Layout,
      text: "Campaigns",
      description: "View and manage all active campaigns",
      link: "/campaigns"
    },
    {
      icon: CheckCircle,
      text: "Prospect Validate",
      description: "Verify and validate prospect information",
      link: "/echidnamail/prospectvalidation"
    },
    {
      icon: Settings,
      text: "Domain Settings",
      description: "Configure domain and system preferences",
      link: "/domain-settings"
    },
    {
      icon: Database,
      text: "CRM",
      description: "Access customer relationship management",
      link: "/echidnamail/crm"
    },
    {
      icon: BarChart2,
      text: "Competitive Analysis",
      description: "Analyze market competition data",
      link: "/competitive-analysis"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto p-6">
        {/* Header */}
        <div className="flex items-center justify-between mb-8 bg-white p-6 rounded-xl border border-gray-200">
          <div className="flex items-center">
            <img src={logo} alt="Company Logo" className="h-12 w-auto" />
            <p className="text-gray-500 ml-4">Select a feature to get started</p>
          </div>
          <div className="h-12 w-12 bg-blue-50 rounded-lg flex items-center justify-center">
            <Layout size={24} className="text-blue-500" />
          </div>
        </div>

        {/* Feature Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              text={feature.text}
              description={feature.description}
              link={feature.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EchidnaDash;
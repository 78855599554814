import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const useEchidnaAccess = () => {
  const [eloading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccess = async () => {
      if (!userInfo) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.post('https://web-production-5ee8.up.railway.app/auth/echcheck', {
          userId: userInfo._id
        });
        
        // Set hasAccess based on response
        setHasAccess(response.data.hasAccess);
        setLoading(false);
      } catch (error) {
        console.error('Error checking Echidna access:', error);
        setHasAccess(false);
        setLoading(false);
      }
    };

    checkAccess();
  }, [userInfo, navigate]);

  return { eloading, hasAccess };
};

// LoadingScreen.js
export const LoadingScreen = () => (
  <div className="flex items-center justify-center h-screen bg-gray-900">
    <div className="text-center">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500 mx-auto mb-4"></div>
      <h2 className="text-xl font-semibold text-gray-200">Loading...</h2>
      <p className="text-gray-400 mt-2">Please wait while we check your access</p>
    </div>
  </div>
);

// NoAccessScreen.js
export const NoAccessScreen = () => (
  <div className="flex items-center justify-center h-screen bg-gray-900">
    <div className="text-center max-w-md p-8 rounded-lg bg-gray-800 border border-gray-700">
      <div className="w-16 h-16 bg-red-500/10 rounded-full flex items-center justify-center mx-auto mb-4">
        <svg className="w-8 h-8 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
      <h2 className="text-2xl font-bold text-white mb-2">Access Denied</h2>
      <p className="text-gray-400 mb-6">You don't have access to this feature. Please contact your administrator for access.</p>
      <button 
        onClick={() => window.location.href = '/dashboard'}
        className="px-6 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 transition duration-300"
      >
        Return to Dashboard
      </button>
    </div>
  </div>
);
import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { 
  Layers, 
  Database, 
  Mail, 
  Brain, 
  MessageCircle,
  ArrowUpRight,
  Activity,
  Clock,
  Radio,
  Info,
  X
} from 'lucide-react';
import Navbar from '../../components/navbar/Navbar.jsx';

const ProductDashboard = () => {
  const { user } = useUser();
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showEchidnaChatPopup, setShowEchidnaChatPopup] = useState(false);

  useEffect(() => {
    // Show Echidna Chat popup on first load
    const hasSeenPopup = localStorage.getItem('echidnaChatPopupSeen');
    if (!hasSeenPopup) {
      const timer = setTimeout(() => {
        setShowEchidnaChatPopup(true);
        localStorage.setItem('echidnaChatPopupSeen', 'true');
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const products = [
    {
      id: 'neptaverse',
      name: 'Neptaverse XR',
      renderIcon: () => <Layers className="h-5 w-5 text-blue-500" />,
      description: 'Reality Engine v2.4.1',
      metrics: {
        status: 'Optimal',
        performance: '99.9%',
        latency: '45ms'
      },
      link: '/neptaverse',
      details: {
        status: 'All systems functioning within optimal parameters',
        performance: 'System operating at peak efficiency',
        latency: 'Average response time across all endpoints'
      },
      info: 'Virtual and extended reality platform providing immersive experiences with real-time rendering and physics simulation.'
    },
    {
      id: 'cyberlake',
      name: 'Cyberlake',
      renderIcon: () => <Database className="h-5 w-5 text-blue-500" />,
      description: 'DataCore v3.1.0',
      metrics: {
        status: 'Optimal',
        performance: '99.9%',
        latency: '1.2 PB'
      },
      link: '/cyberlake',
      details: {
        status: 'All data centers operating normally',
        performance: 'Current system throughput',
        latency: 'Total data processed'
      },
      info: 'Enterprise-grade data lake solution with advanced analytics and real-time processing capabilities.'
    },
    {
      id: 'echidna-mail',
      name: 'Echidna Mail',
      renderIcon: () => <Mail className="h-5 w-5 text-blue-500" />,
      description: 'CommSys v1.8.5',
      metrics: {
        status: 'Optimal',
        performance: '99.9%',
        latency: '0.1%'
      },
      link: '/echidnamail/dashboard',
      details: {
        status: 'Mail delivery systems running optimally',
        performance: 'Current delivery success rate',
        latency: 'Bounce rate'
      },
      info: 'Secure enterprise email solution with advanced threat protection and automation capabilities.'
    },
    {
      id: 'echidna-llm',
      name: 'Echidna LLM',
      renderIcon: () => <Brain className="h-5 w-5 text-blue-500" />,
      description: 'CoreAI v4.0.2',
      metrics: {
        status: 'Warning',
        performance: '94.5%',
        latency: '150ms'
      },
      link: '/products/echidna-llm',
      details: {
        status: 'Minor performance degradation detected',
        performance: 'Model accuracy rate',
        latency: 'Average inference time'
      },
      info: 'Advanced language model system providing natural language processing and generation capabilities.'
    },
    {
      id: 'echidna-chat',
      name: 'Echidna Chat',
      renderIcon: () => <MessageCircle className="h-5 w-5 text-blue-500" />,
      description: 'NetLink v2.2.0',
      metrics: {
        status: 'Optimal',
        performance: '99.9%',
        latency: '120ms'
      },
      link: '/echidna/chat',
      details: {
        status: 'All communication channels active',
        performance: 'Message delivery rate',
        latency: 'Average message delay'
      },
      info: 'Real-time communication platform with enterprise-grade security and scalability.'
    }
  ];

  const MetricIcon = ({ type }) => {
    switch(type) {
      case 'status': return <Radio className="w-4 h-4 text-blue-500 animate-pulse" />;
      case 'performance': return <Activity className="w-4 h-4 text-blue-500" />;
      case 'latency': return <Clock className="w-4 h-4 text-blue-500" />;
      default: return null;
    }
  };

  const getStatusColor = (status) => {
    switch(status) {
      case 'Optimal': return 'text-green-500';
      case 'Warning': return 'text-yellow-500';
      case 'Critical': return 'text-red-500';
      default: return 'text-gray-500';
    }
  };

  const handleCardClick = (productId) => {
    window.location.href = products.find(p => p.id === productId).link;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Navbar />
      
      <div className="flex-1 px-4 py-6 sm:px-6">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-6">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900">System Status Dashboard</h2>
            <p className="text-xs sm:text-sm text-gray-600 mt-2">Real-time monitoring and metrics</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {products.map((product) => (
              <div 
                key={product.id}
                onClick={() => handleCardClick(product.id)}
                className="group bg-white rounded-lg shadow-sm hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer relative"
              >
                <div className="p-4 sm:p-6">
                  <div className="flex justify-between items-start mb-4 sm:mb-6">
                    <div className="flex items-center space-x-2 sm:space-x-4">
                      <div className="p-2 rounded-md bg-blue-50">
                        {product.renderIcon()}
                      </div>
                      <div>
                        <h3 className="text-base sm:text-lg font-semibold text-gray-900">{product.name}</h3>
                        <p className="text-xs sm:text-sm text-gray-600 font-mono">{product.description}</p>
                      </div>
                    </div>
                    
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedProduct(selectedProduct === product.id ? null : product.id);
                      }}
                      className="p-2 hover:bg-gray-100 rounded-full"
                    >
                      <Info className="w-4 sm:w-5 h-4 sm:h-5 text-gray-400 hover:text-blue-500" />
                    </button>
                  </div>

                  <div className="grid grid-cols-3 gap-2 sm:gap-4">
                    {Object.entries(product.metrics).map(([key, value]) => (
                      <div key={key} 
                        className="relative group"
                        onMouseEnter={() => setActiveTooltip(`${product.id}-${key}`)}
                        onMouseLeave={() => setActiveTooltip(null)}
                      >
                        <div className="p-2 sm:p-3 bg-gray-50 rounded-md">
                          <div className="flex items-center space-x-1 sm:space-x-2 mb-1 sm:mb-2">
                            <MetricIcon type={key} />
                            <span className="text-[10px] sm:text-xs font-medium text-gray-600 uppercase">{key}</span>
                          </div>
                          <span className={`text-xs sm:text-sm font-medium font-mono ${key === 'status' ? getStatusColor(value) : 'text-gray-900'}`}>
                            {value}
                          </span>
                        </div>
                        
                        {activeTooltip === `${product.id}-${key}` && (
                          <div className="absolute z-10 bottom-full mb-2 left-1/2 transform -translate-x-1/2">
                            <div className="bg-gray-900 text-white text-xs sm:text-sm rounded-md py-1 px-2 whitespace-nowrap">
                              {product.details[key]}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                
                <div className="h-1 bg-gradient-to-r from-blue-500 to-blue-600" />
                
                {selectedProduct === product.id && (
                  <div className="absolute inset-0 bg-white bg-opacity-95 p-4 sm:p-6 flex flex-col">
                    <div className="flex justify-between items-start">
                      <h4 className="text-base sm:text-lg font-semibold text-gray-900">{product.name}</h4>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedProduct(null);
                        }}
                        className="p-1 hover:bg-gray-100 rounded-full"
                      >
                        <X className="w-4 sm:w-5 h-4 sm:h-5 text-gray-400" />
                      </button>
                    </div>
                    <p className="mt-2 sm:mt-4 text-xs sm:text-sm text-gray-600">{product.info}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {showEchidnaChatPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative">
            <button 
              onClick={() => setShowEchidnaChatPopup(false)}
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
            >
              <X className="w-5 h-5" />
            </button>
            <div className="flex items-center space-x-4 mb-4">
              <MessageCircle className="w-10 h-10 text-blue-500" />
              <h3 className="text-lg font-semibold">Try Echidna Chat</h3>
            </div>
            <p className="text-gray-600 mb-4">
              Experience our flagship thinking model with advanced natural language capabilities. 
              Seamless communication, enterprise-grade security.
            </p>
            <button 
              onClick={() => {
                window.location.href = '/echidna/chat';
                setShowEchidnaChatPopup(false);
              }}
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition"
            >
              Launch Echidna Chat
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDashboard;
import React, { useState, useEffect } from 'react';
import { ChevronRight, Inbox, AlertCircle, Loader2, Menu, X } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useEchidnaAccess, LoadingScreen, NoAccessScreen  } from '../../assets/useraccess';
const EmailInbox = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { eloading, hasAccess } = useEchidnaAccess();

  const fetchEmails = async (userId) => {
    try {
      const response = await fetch('http://localhost:5001/echidna/inbox', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId
        })
      });

      if (!response.ok) throw new Error('Failed to fetch emails');
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const loadEmails = async () => {
      try {
        setLoading(true);
        const fetchedEmails = await fetchEmails(userInfo._id);
        setEmails(fetchedEmails);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadEmails();
  }, [userInfo._id]);

  const renderEmailList = () => {
    if (loading) return (
      <div className="flex justify-center items-center h-full">
        <Loader2 className="animate-spin text-blue-500" size={48} />
      </div>
    );

    if (error) return (
      <div className="flex items-center p-4 bg-red-50 text-red-800 rounded">
        <AlertCircle className="mr-2" />
        {error}
      </div>
    );

    return emails.map(email => (
      <div 
        key={email.id} 
        className="flex items-center p-4 hover:bg-blue-50 border-b cursor-pointer transition-colors"
        onClick={() => {
          setSelectedEmail(email);
          setIsMobileMenuOpen(false);
        }}
      >
        <div className="flex-grow">
          <div className="font-semibold text-gray-800 text-sm sm:text-base">{email.sender}</div>
          <div className="text-xs sm:text-sm text-gray-600 truncate">{email.subject}</div>
        </div>
        <ChevronRight className="text-blue-500 w-4 h-4 sm:w-5 sm:h-5" />
      </div>
    ));
  };

  const renderEmailDetail = () => {
    if (!selectedEmail) return (
      <div className="hidden md:flex justify-center items-center h-full text-gray-500">
        Select an email to view details
      </div>
    );

    return (
      <div className="p-4 bg-white border-l">
        <div className="font-bold text-lg mb-2">{selectedEmail.subject}</div>
        <div className="text-gray-600 mb-4">From: {selectedEmail.sender}</div>
        <div className="text-gray-800">{selectedEmail.body}</div>
      </div>
    );
  };

  if (eloading) {
    return <LoadingScreen />;
  }

  if (!hasAccess) {
    return <NoAccessScreen />;
  }


  return (
    <div className="flex flex-col md:flex-row w-full h-screen max-w-6xl mx-auto bg-white shadow-lg">
      {/* Mobile Header */}
      <div className="md:hidden bg-blue-600 text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <Inbox className="mr-2" />
          <h2 className="text-lg font-bold">Email Inbox</h2>
        </div>
        <button 
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="focus:outline-none"
        >
          {isMobileMenuOpen ? <X /> : <Menu />}
        </button>
      </div>

      {/* Sidebar */}
      <div className={`
        ${isMobileMenuOpen ? 'block' : 'hidden'} md:block 
        w-full md:w-1/3 lg:w-1/4 bg-white border-r overflow-y-auto
      `}>
        <div className="hidden md:flex bg-blue-600 text-white p-4 items-center">
          <Inbox className="mr-2" />
          <h2 className="text-lg font-bold">Email Inbox</h2>
        </div>
        {renderEmailList()}
      </div>

      {/* Email Detail */}
      <div className="flex-grow md:block bg-gray-50">
        {renderEmailDetail()}
      </div>
    </div>
  );
};

export default EmailInbox;
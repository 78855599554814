
import { 
  ChevronRight, Bot, Zap, Users, Mail, 
  BarChart3, Brain, Laptop, Shield, Calendar,
  Inbox, Send, Clock, Bell, Settings, Eye,
  RefreshCw, PieChart, ArrowUpRight, Globe,
  Target, Sparkles, Lock, Database, Copy,
  MessageCircle, LineChart, Filter, Code,
  UserPlus, Briefcase, Trophy, TrendingUp,
  BookOpen,  HeartHandshake, 
   Building2, Workflow
} from 'lucide-react';
import React, { useState, useEffect } from 'react';


const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const products = [
    { name: "Cyberlake", description: "Enterprise analytics" },
    { name: "Neptaverse", description: "Extended reality" },
    { name: "Echidna Suite", description: "Sales automation" },
    { name: "CodegenV0", description: "AI Development" },
  ];

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-black/80 backdrop-blur-md py-4" : "bg-transparent py-6"
      }`}
    >
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between">
          <img
            src="https://cybverlake-test.s3.ap-south-1.amazonaws.com/MBEES+2+transparent.png"
            alt="Metabees Logo"
            className="h-8 w-auto brightness-200"
          />
          <div className="hidden md:flex items-center space-x-8">
            {products.map((product, index) => (
              <a
                key={index}
                href={`${product.name.toLowerCase()}`}
                className="text-gray-300 hover:text-white transition-colors duration-300 text-sm font-medium"
              >
                {product.name}
              </a>
            ))}
            <a
              href="llm"
              className="text-gray-300 hover:text-white transition-colors duration-300 text-sm font-medium"
            >
              Echidna LLM
            </a>
            <a
              href="/login"
              className="bg-white/10 backdrop-blur-sm border border-white/20 text-white px-6 py-2 rounded-lg hover:bg-white/20 transition-all duration-300"
            >
              Get Access
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
const Hero = () => {
  return (
    <div className="relative h-screen">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-black via-purple-900/10 to-black z-10" />
        {/* Abstract pattern background */}
        <div className="absolute inset-0 opacity-20">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_rgba(100,50,200,0.2)_0%,_rgba(0,0,0,0.3)_50%)]" />
        </div>
      </div>
      <div className="relative z-20 h-full flex items-center">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl">
            <div className="inline-block">
              <span className="px-3 py-1 rounded-full text-xs font-medium bg-purple-500/10 text-purple-400 border border-purple-500/20 mb-4">
                Introducing Echidna AI
              </span>
            </div>
            <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-purple-400">
                The Future of CRM is Powered by AI
              </span>
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-gray-400 max-w-2xl">
              Transform your customer relationships with AI-driven insights, automated outreach, and intelligent sales forecasting.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <a href="/contact"><button className="group bg-gradient-to-r from-purple-600 to-purple-500 px-8 py-4 rounded-lg hover:from-purple-500 hover:to-purple-400 transition-all duration-300 flex items-center justify-center">
                Start Free Trial
                <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform" />
              </button></a>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

{/* Rest of the components remain exactly the same */}
const Features = () => {
  const features = [
    {
      icon: <Brain className="w-12 h-12 text-purple-400" />,
      title: "AI-Powered Insights",
      description: "Get real-time customer insights and predictive analytics to make data-driven decisions."
    },
    {
      icon: <Zap className="w-12 h-12 text-blue-400" />,
      title: "Automated Outreach",
      description: "Multi-channel automation for emails, calls, and social media engagement."
    },
    {
      icon: <Users className="w-12 h-12 text-green-400" />,
      title: "Smart Lead Scoring",
      description: "AI-driven lead scoring and qualification to focus on high-value prospects."
    },
    {
      icon: <Calendar className="w-12 h-12 text-red-400" />,
      title: "Intelligent Scheduling",
      description: "Automated meeting scheduling with smart time-zone detection."
    }
  ];

  return (
    <section className="py-20 bg-black border-t border-gray-800">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Core Features</h2>
          <p className="text-gray-400">Everything you need to scale your sales operations</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="p-6 bg-gray-900/50 rounded-lg border border-gray-800 hover:border-purple-500/50 transition-all duration-300">
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const AutomationMetrics = () => {
  const metrics = [
    { label: "Time Saved", value: "85%", description: "Average reduction in manual tasks" },
    { label: "Lead Response", value: "2min", description: "Average response time to new leads" },
    { label: "Conversion Rate", value: "+45%", description: "Increase in conversion rates" },
    { label: "ROI", value: "3.8x", description: "Average return on investment" }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {metrics.map((metric, index) => (
            <div key={index} className="text-center p-6">
              <div className="text-4xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-600">
                {metric.value}
              </div>
              <div className="text-xl font-medium mb-1">{metric.label}</div>
              <p className="text-gray-400">{metric.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const ComparisonTable = () => {
  return (
    <section className="py-20 bg-black">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Why Choose Echidna mail CRM?</h2>
          <p className="text-gray-400">See how we compare to traditional CRM solutions</p>
        </div>
        <div className="max-w-4xl mx-auto overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-800">
                <th className="text-left p-4">Feature</th>
                <th className="p-4">NexusCRM AI</th>
                <th className="p-4">Traditional CRM</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-gray-800">
                <td className="p-4">AI-Powered Analytics</td>
                <td className="p-4 text-center text-green-400">Advanced</td>
                <td className="p-4 text-center text-gray-400">Basic</td>
              </tr>
              <tr className="border-b border-gray-800">
                <td className="p-4">Automated Outreach</td>
                <td className="p-4 text-center text-green-400">Included</td>
                <td className="p-4 text-center text-gray-400">Limited</td>
              </tr>
              <tr className="border-b border-gray-800">
                <td className="p-4">Response Time</td>
                <td className="p-4 text-center text-green-400">2 minutes</td>
                <td className="p-4 text-center text-gray-400">24 hours</td>
              </tr>
              <tr className="border-b border-gray-800">
                <td className="p-4">Setup Time</td>
                <td className="p-4 text-center text-green-400">Hours</td>
                <td className="p-4 text-center text-gray-400">Weeks</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

const StatsDashboard = () => {
  return (
    <section className="py-20 bg-black border-t border-gray-800">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Real-Time Email Analytics</h2>
          <p className="text-gray-400">Monitor your email campaigns with powerful analytics</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Activity Card */}
          <div className="bg-gray-900/50 p-6 rounded-lg border border-gray-800">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Today's Activity</h3>
              <RefreshCw className="w-5 h-5 text-purple-400" />
            </div>
            <div className="space-y-4">
              {[
                { label: 'Emails Sent', value: '2,453' },
                { label: 'Open Rate', value: '48.2%' },
                { label: 'Click Rate', value: '12.8%' }
              ].map((stat, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span className="text-gray-400">{stat.label}</span>
                  <span className="font-medium">{stat.value}</span>
                </div>
              ))}
            </div>
          </div>
          
          {/* Performance Graph */}
          <div className="bg-gray-900/50 p-6 rounded-lg border border-gray-800 col-span-2">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Campaign Performance</h3>
              <PieChart className="w-5 h-5 text-purple-400" />
            </div>
            <div className="h-48 flex items-center justify-center">
              <p className="text-gray-400">Interactive performance graph would render here</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// New Integration Showcase Component
const IntegrationShowcase = () => {
  const integrations = [
    { name: 'Gmail', color: 'text-red-400' },
    { name: 'Outlook', color: 'text-blue-400' },
    { name: 'Salesforce', color: 'text-sky-400' },
    { name: 'Slack', color: 'text-green-400' },
    { name: 'Microsoft Teams', color: 'text-purple-400' },
    { name: 'Zoom', color: 'text-blue-400' }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Seamless Integrations</h2>
          <p className="text-gray-400">Connect with your favorite tools</p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {integrations.map((integration, index) => (
            <div key={index} className="flex flex-col items-center p-6 bg-gray-900/50 rounded-lg border border-gray-800 hover:border-purple-500/50 transition-all duration-300">
              <span className={`text-2xl font-bold ${integration.color}`}>{integration.name}</span>
              <ArrowUpRight className="w-4 h-4 mt-2 text-gray-400" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// New Email Template Showcase Component
const EmailTemplates = () => {
  const templates = [
    {
      title: "Welcome Series",
      description: "Automated onboarding emails",
      metrics: { openRate: "65%", conversion: "28%" }
    },
    {
      title: "Re-engagement",
      description: "Win back inactive users",
      metrics: { openRate: "45%", conversion: "15%" }
    },
    {
      title: "Product Updates",
      description: "Keep users informed",
      metrics: { openRate: "52%", conversion: "22%" }
    }
  ];

  return (
    <section className="py-20 bg-black border-t border-gray-800">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Smart Email Templates</h2>
          <p className="text-gray-400">AI-powered templates that convert</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {templates.map((template, index) => (
            <div key={index} className="p-6 bg-gray-900/50 rounded-lg border border-gray-800 hover:border-purple-500/50 transition-all duration-300">
              <Mail className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-bold mb-2">{template.title}</h3>
              <p className="text-gray-400 mb-4">{template.description}</p>
              <div className="flex justify-between text-sm">
                <span className="text-green-400">Open Rate: {template.metrics.openRate}</span>
                <span className="text-purple-400">Conversion: {template.metrics.conversion}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const CTASection = () => {
  return (
    <section className="py-20 bg-gradient-to-r from-purple-900/20 via-black to-purple-900/20 border-t border-gray-800">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Ready to Transform Your Sales Process?</h2>
        <p className="text-xl text-gray-400 mb-8 max-w-2xl mx-auto">
          Join thousands of companies already using NexusCRM AI to drive their sales growth
        </p>
        <a href="/contact"><button className="group bg-gradient-to-r from-purple-600 to-purple-500 px-8 py-4 rounded-lg hover:from-purple-500 hover:to-purple-400 transition-all duration-300 flex items-center justify-center mx-auto">
          Get Started Now
          <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform" />
        </button></a>
      </div>
    </section>
  );
};

const AIAssistantPreview = () => {
  const features = [
    { icon: <Sparkles className="w-6 h-6" />, text: "Smart Content Generation" },
    { icon: <Target className="w-6 h-6" />, text: "Audience Segmentation" },
    { icon: <Clock className="w-6 h-6" />, text: "Optimal Send Time" },
    { icon: <MessageCircle className="w-6 h-6" />, text: "Tone Analysis" }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">AI Email Assistant</h2>
            <p className="text-xl text-gray-400 mb-8">
              Let our AI help you craft the perfect email campaign with smart suggestions and real-time optimization.
            </p>
            <div className="grid grid-cols-2 gap-6">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center space-x-3 text-purple-400">
                  {feature.icon}
                  <span className="text-white">{feature.text}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">AI Writing Assistant</h3>
              <Bot className="w-6 h-6 text-purple-400" />
            </div>
            <div className="space-y-4">
              <div className="bg-black/50 p-4 rounded border border-gray-800">
                <p className="text-gray-400">Subject: Welcome to Echidna Mail! 👋</p>
                <div className="mt-2 flex items-center space-x-2">
                  <span className="px-2 py-1 bg-purple-500/10 text-purple-400 rounded text-sm">
                    98% Engagement Score
                  </span>
                  <span className="px-2 py-1 bg-green-500/10 text-green-400 rounded text-sm">
                    Friendly Tone
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// New A/B Testing Dashboard Component
const ABTestingDashboard = () => {
  return (
    <section className="py-20 bg-black border-t border-gray-800">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Advanced A/B Testing</h2>
          <p className="text-gray-400">Optimize every element of your emails</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="col-span-2 bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-bold">Current Test Results</h3>
              <div className="flex space-x-2">
                <Filter className="w-5 h-5 text-purple-400" />
                <LineChart className="w-5 h-5 text-purple-400" />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="p-4 bg-black/50 rounded border border-gray-800">
                <h4 className="text-lg font-medium mb-2">Version A</h4>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-400">Open Rate</span>
                    <span className="text-green-400">24.8%</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">Click Rate</span>
                    <span className="text-green-400">3.2%</span>
                  </div>
                </div>
              </div>
              <div className="p-4 bg-black/50 rounded border border-gray-800">
                <h4 className="text-lg font-medium mb-2">Version B</h4>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-400">Open Rate</span>
                    <span className="text-purple-400">28.1%</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-400">Click Rate</span>
                    <span className="text-purple-400">4.5%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <h3 className="text-xl font-bold mb-4">Test Elements</h3>
            <div className="space-y-4">
              {['Subject Lines', 'Send Times', 'Content Blocks', 'CTAs'].map((element, index) => (
                <div key={index} className="flex items-center justify-between p-3 bg-black/50 rounded border border-gray-800">
                  <span>{element}</span>
                  <Copy className="w-4 h-4 text-purple-400" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// New Security Features Component
const SecurityFeatures = () => {
  const features = [
    {
      icon: <Lock className="w-12 h-12 text-purple-400" />,
      title: "Enterprise-Grade Security",
      description: "End-to-end encryption for all your email data"
    },
    {
      icon: <Shield className="w-12 h-12 text-blue-400" />,
      title: "Compliance Ready",
      description: "GDPR, CCPA, and other regulatory compliance built-in"
    },
    {
      icon: <Eye className="w-12 h-12 text-green-400" />,
      title: "Advanced Monitoring",
      description: "Real-time threat detection and prevention"
    },
    {
      icon: <Database className="w-12 h-12 text-red-400" />,
      title: "Data Protection",
      description: "Automated backups and data recovery"
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Enterprise Security</h2>
          <p className="text-gray-400">Your data security is our top priority</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="p-6 bg-gray-900/50 rounded-lg border border-gray-800 hover:border-purple-500/50 transition-all duration-300">
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// New API Integration Component
const APISection = () => {
  return (
    <section className="py-20 bg-black border-t border-gray-800">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Developer-Friendly API</h2>
            <p className="text-xl text-gray-400 mb-8">
              Integrate email automation into your workflow with our comprehensive API
            </p>
            <div className="space-y-4">
              {[
                "RESTful API endpoints",
                "Webhook support",
                "Detailed documentation",
                "SDK support for major languages"
              ].map((feature, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <Code className="w-5 h-5 text-purple-400" />
                  <span>{feature}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Quick Start Example</h3>
              <Copy className="w-5 h-5 text-purple-400" />
            </div>
            <pre className="bg-black/50 p-4 rounded text-sm overflow-x-auto">
              <code className="text-purple-400">
                {`curl -X POST https://api.echidna.mail/v1/send \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -d '{
    "to": "recipient@example.com",
    "subject": "Hello from Echidna Mail",
    "template_id": "welcome_email"
  }'`}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </section>
  );
};

// Updated main component with new sections
const AISalesAssistant = () => {
  const assistantFeatures = [
    {
      icon: <Brain className="w-6 h-6 text-purple-400" />,
      title: "Smart Lead Qualification",
      description: "AI automatically scores and ranks leads"
    },
    {
      icon: <MessageCircle className="w-6 h-6 text-blue-400" />,
      title: "Conversation Intelligence",
      description: "Real-time email response suggestions"
    },
    {
      icon: <Target className="w-6 h-6 text-green-400" />,
      title: "Opportunity Detection",
      description: "Identifies potential upsell opportunities"
    },
    {
      icon: <Clock className="w-6 h-6 text-yellow-400" />,
      title: "Follow-up Automation",
      description: "Smart scheduling of follow-up emails"
    }
  ];

  return (
    <section className="py-20 bg-black">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">AI Sales Assistant</h2>
          <p className="text-gray-400">Your team's intelligent sales companion</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {assistantFeatures.map((feature, index) => (
            <div key={index} className="bg-gray-900/50 rounded-lg border border-gray-800 p-6 hover:border-purple-500/50 transition-all duration-300">
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-lg font-bold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Sales Performance Analytics
const SalesPerformanceMetrics = () => {
  return (
    <section className="py-20 bg-black border-t border-gray-800">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Team Performance Card */}
          <div className="bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-bold">Team Performance</h3>
              <TrendingUp className="w-6 h-6 text-purple-400" />
            </div>
            <div className="space-y-4">
              {[
                { label: "Deals Closed", value: "127", growth: "+12%" },
                { label: "Revenue Generated", value: "$1.2M", growth: "+24%" },
                { label: "Average Deal Size", value: "$9,450", growth: "+8%" }
              ].map((metric, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span className="text-gray-400">{metric.label}</span>
                  <div className="flex items-center space-x-2">
                    <span className="font-bold">{metric.value}</span>
                    <span className="text-green-400 text-sm">{metric.growth}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* AI Insights Card */}
          <div className="bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-bold">AI Insights</h3>
              <Sparkles className="w-6 h-6 text-purple-400" />
            </div>
            <div className="space-y-4">
              {[
                "Email response rates peak at 10 AM",
                "Most successful deals close within 14 days",
                "Top-performing subject line pattern detected",
                "Recommended follow-up timing: 3 days"
              ].map((insight, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <Brain className="w-4 h-4 text-purple-400" />
                  <span className="text-gray-400">{insight}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Revenue Forecast Card */}
          <div className="bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-bold">AI Revenue Forecast</h3>
              <ArrowUpRight className="w-6 h-6 text-purple-400" />
            </div>
            <div className="space-y-6">
              <div className="h-32 flex items-center justify-center border-b border-gray-800">
                <span className="text-4xl font-bold text-purple-400">$2.4M</span>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-400">Confidence Score</span>
                  <span className="text-green-400">92%</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-400">Time Period</span>
                  <span>Next Quarter</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// AI Deal Room
const AIDealRoom = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">AI Deal Room</h2>
            <p className="text-xl text-gray-400 mb-8">
              Collaborative space where AI and your sales team work together to close deals faster
            </p>
            <div className="grid grid-cols-2 gap-6">
              {[
                { icon: <HeartHandshake className="w-6 h-6" />, text: "Smart Deal Tracking" },
                { icon: <ArrowUpRight className="w-6 h-6" />, text: "Risk Assessment" },
                { icon: <Workflow className="w-6 h-6" />, text: "Process Automation" },
                { icon: <Building2 className="w-6 h-6" />, text: "Account Intelligence" }
              ].map((feature, index) => (
                <div key={index} className="flex items-center space-x-3 text-purple-400">
                  {feature.icon}
                  <span className="text-white">{feature.text}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <div className="space-y-4">
              <div className="flex items-center justify-between p-4 bg-black/50 rounded border border-gray-800">
                <div>
                  <h4 className="font-medium">Enterprise Deal #4421</h4>
                  <p className="text-gray-400 text-sm">Last updated 2 hours ago</p>
                </div>
                <span className="px-3 py-1 bg-purple-500/10 text-purple-400 rounded-full text-sm">
                  92% Probability
                </span>
              </div>
              <div className="p-4 bg-black/50 rounded border border-gray-800">
                <h4 className="font-medium mb-2">AI Recommendations</h4>
                <ul className="space-y-2 text-gray-400">
                  <li className="flex items-center space-x-2">
                    <Brain className="w-4 h-4 text-purple-400" />
                    <span>Schedule follow-up meeting within 48 hours</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <Brain className="w-4 h-4 text-purple-400" />
                    <span>Prepare ROI calculation based on client data</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <Brain className="w-4 h-4 text-purple-400" />
                    <span>Address security compliance requirements</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Smart Territory Management
const TerritoryManagement = () => {
  return (
    <section className="py-20 bg-black border-t border-gray-800">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Smart Territory Management</h2>
          <p className="text-gray-400">AI-optimized territory planning and account distribution</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="col-span-2 bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-bold">Territory Overview</h3>
              <Globe className="w-6 h-6 text-purple-400" />
            </div>
            <div className="grid grid-cols-2 gap-4">
              {[
                { region: "North America", accounts: 1245, potential: "$2.8M" },
                { region: "Europe", accounts: 892, potential: "$1.9M" },
                { region: "Asia Pacific", accounts: 634, potential: "$1.4M" },
                { region: "Latin America", accounts: 423, potential: "$0.9M" }
              ].map((territory, index) => (
                <div key={index} className="p-4 bg-black/50 rounded border border-gray-800">
                  <h4 className="font-medium mb-2">{territory.region}</h4>
                  <div className="space-y-1">
                    <div className="flex justify-between">
                      <span className="text-gray-400">Accounts</span>
                      <span>{territory.accounts}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-400">Potential</span>
                      <span className="text-purple-400">{territory.potential}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-gray-900/50 rounded-lg border border-gray-800 p-6">
            <h3 className="text-xl font-bold mb-6">AI Recommendations</h3>
            <div className="space-y-4">
              {[
                "Redistribute APAC accounts for better coverage",
                "Increase team size in growing EU market",
                "Optimize account distribution based on deal size",
                "Focus on high-potential enterprise accounts"
              ].map((recommendation, index) => (
                <div key={index} className="flex items-center space-x-3 p-3 bg-black/50 rounded border border-gray-800">
                  <Target className="w-4 h-4 text-purple-400" />
                  <span className="text-gray-400">{recommendation}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Updated main component with new sections
const Echidna = () => {
  return (
    <div className="bg-black text-white">
      <Navigation />
      <Hero />

      <AISalesAssistant />
      <StatsDashboard />
      <SalesPerformanceMetrics />
      <AIAssistantPreview />
      <EmailTemplates />
      <AIDealRoom />
      <TerritoryManagement />
      <ABTestingDashboard />
      <AutomationMetrics />
      <SecurityFeatures />
      <APISection />
      <IntegrationShowcase />
      <ComparisonTable />
      <CTASection />
    </div>
  );
};

export default Echidna;
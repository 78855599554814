import React, { useState, useEffect } from 'react';
import { User, Mail, AlertCircle, RefreshCw, Plus } from 'lucide-react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEchidnaAccess, LoadingScreen, NoAccessScreen  } from '../../assets/useraccess';
const UserInfo = () => {
  const navigate = useNavigate();
  const [emailCredits, setEmailCredits] = useState(null);
  const [loading, setLoading] = useState(true);
  const { eloading, hasAccess } = useEchidnaAccess();
  const [error, setError] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);

  const fetchEmailCredits = async () => {
    try {
      setLoading(true);
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/el', {
        userId: userInfo._id,
      });
      
      if (response.data && typeof response.data.mails !== 'undefined') {
        setEmailCredits(response.data.mails);
      } else {
        throw new Error('Invalid response format');
      }
      
      setError(null);
    } catch (err) {
      console.error('Detailed error:', err);
      setError(err.response?.data?.error || 'Failed to fetch email credits. Please try again.');
      setEmailCredits(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && userInfo._id) {
      fetchEmailCredits();
    }
  }, [userInfo]);

  const formatCredits = (credits) => {
    if (credits === null || credits === undefined) return '0';
    return credits.toString();
  };

  const handleGetMoreCredits = () => {
    navigate('/buy-credits');
  };
  if (eloading) {
    return <LoadingScreen />;
  }

  if (!hasAccess) {
    return <NoAccessScreen />;
  }


  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="mb-8 text-center">
        <h1 className="text-2xl font-bold text-gray-800">Account Information</h1>
        <p className="text-gray-600 mt-2">View and manage your account details</p>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        {/* User Profile Card */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="mb-4">
            <div className="flex items-center space-x-2">
              <User className="w-5 h-5 text-blue-500" />
              <h2 className="text-lg font-semibold text-gray-800">Profile Details</h2>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-center mb-6">
              <div className="w-20 h-20 bg-blue-100 rounded-full flex items-center justify-center">
                <User className="w-10 h-10 text-blue-500" />
              </div>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500">Email</p>
              <p className="font-medium">{userInfo?.email || 'N/A'}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500">Account Created</p>
              <p className="font-medium">
                {userInfo?.createdAt ? new Date(userInfo.createdAt).toLocaleDateString() : 'N/A'}
              </p>
            </div>
          </div>
        </div>

        {/* Email Credits Card */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="mb-4">
            <div className="flex items-center space-x-2">
              <Mail className="w-5 h-5 text-blue-500" />
              <h2 className="text-lg font-semibold text-gray-800">Email Credits</h2>
            </div>
          </div>
          <div className="space-y-6">
            {loading ? (
              <div className="flex items-center justify-center py-8">
                <RefreshCw className="w-6 h-6 text-blue-500 animate-spin" />
              </div>
            ) : error ? (
              <div className="flex items-center space-x-2 text-red-500 bg-red-50 p-4 rounded-lg">
                <AlertCircle className="w-5 h-5" />
                <span>{error}</span>
              </div>
            ) : (
              <>
                <div className="text-center">
                  <div className="text-4xl font-bold text-blue-500">
                    {formatCredits(emailCredits)}
                  </div>
                  <p className="text-gray-600 mt-2">Remaining Credits</p>
                </div>
                <div className="bg-blue-50 p-4 rounded-lg">
                  <h4 className="font-medium text-blue-700 mb-2">Credit Usage</h4>
                  <p className="text-sm text-blue-600">
                    Each email sent deducts 1 credit from your account.
                  </p>
                </div>
              </>
            )}
            <div className="flex flex-col space-y-3">
              <button
                onClick={fetchEmailCredits}
                className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 flex items-center justify-center space-x-2"
              >
                <RefreshCw className="w-4 h-4" />
                <span>Refresh Credits</span>
              </button>
              
              <button
                onClick={handleGetMoreCredits}
                className="w-full px-4 py-2 bg-white border-2 border-blue-500 text-blue-500 rounded-md hover:bg-blue-50 transition duration-300 flex items-center justify-center space-x-2"
              >
                <Plus className="w-4 h-4" />
                <span>Get More Credits</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import { useEchidnaAccess, LoadingScreen, NoAccessScreen  } from '../../assets/useraccess';
function BuyCredits() {
  const navigate = useNavigate();
    const { eloading, hasAccess } = useEchidnaAccess();
  const { userInfo } = useSelector((state) => state.auth);
  const userId = userInfo._id;  // add user ID state
  console.log(userId);

  
  const plans = [
    {
      name: "Starter",
      credits: 20,
      price: 5,
      stripeProductId: "prod_123456789",
      stripeCheckoutSessionId: "cs_test_123456789",
    },
    {
      name: "Growth",
      credits: 500,
      price: 25,
      stripeProductId: "prod_987654321",
      stripeCheckoutSessionId: "cs_test_987654321",
    },
    {
      name: "Enterprise",
      credits: 1000,
      price: 150,
      stripeProductId: "prod_abcdef123",
      stripeCheckoutSessionId: "cs_test_abcdef123",
    },
  ];

  const handleBuyNowStarter = async () => {
    try {
      const stripeResponse = await fetch('https://web-production-5ee8.up.railway.app/payments/payc/5', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userid: userId,
        }),
      });
  
      if (stripeResponse.ok) {
        const checkoutSessionData = await stripeResponse.json();
        const stripeCheckoutURL = checkoutSessionData.url;
        const stripeToken = checkoutSessionData.token;
  
        // Redirect the user to the Stripe Checkout URL
        window.location.href = stripeCheckoutURL;
      } else {
        const errorData = await stripeResponse.json();
        console.error('Failed to get Stripe Checkout URL:', errorData.error);
        alert('Failed to get Stripe Checkout URL. Please try again later.');
      }
    } catch (error) {
      console.error('Error in handleBuyNowStarter:', error);
      alert('An unexpected error occurred. Please try again later.');
    }
  };


  const handleBuyNowGrowth = async () => {
    try {
      const stripeResponse = await fetch('https://web-production-5ee8.up.railway.app/payments/payc/20', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stripeProductId: plans[1].stripeProductId,
          stripeCheckoutSessionId: plans[1].stripeCheckoutSessionId,
          userId: userId, // add user ID to request body
        }),
      });

      if (stripeResponse.ok) {
        const checkoutSessionData = await stripeResponse.json();
        const stripeCheckoutURL = checkoutSessionData.url;

        // Redirect the user to the Stripe Checkout URL
        window.location.href = stripeCheckoutURL;
      } else {
        console.error('Failed to get Stripe Checkout URL.');
        alert('Failed to get Stripe Checkout URL.');
      }
    } catch (error) {
      console.log(error);
      alert('Failed to get Stripe Checkout URL.');
    }
  };

  const handleBuyNowEnterprise = async () => {
    try {
      const stripeResponse = await fetch('https://web-production-5ee8.up.railway.app/payments/payc/150', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stripeProductId: plans[2].stripeProductId,
          stripeCheckoutSessionId: plans[2].stripeCheckoutSessionId,
          userId: userId, // add user ID to request body
        }),
      });

      if (stripeResponse.ok) {
        const checkoutSessionData = await stripeResponse.json();
        const stripeCheckoutURL = checkoutSessionData.url;

        // Redirect the user to the Stripe Checkout URL
        window.location.href = stripeCheckoutURL;
      } else {
        console.error('Failed to get Stripe Checkout URL.');
        alert('Failed to get Stripe Checkout URL.');
      }
    } catch (error) {
      console.log(error);
      alert('Failed to get Stripe Checkout URL.');
    }
  };
   if (eloading) {
      return <LoadingScreen />;
    }
  
    if (!hasAccess) {
      return <NoAccessScreen />;
    }

  return (
    <div className="relative min-h-screen overflow-hidden bg-gray-900">
      {/* Video background */}
      {/* ... */}

      <div className="relative z-10 min-h-screen bg-black bg-opacity-60">
        <Navbar />
        <div className="container mx-auto px-4 py-16 overflow-hidden">
          <h1 className="text-4xl font-extrabold text-center text-purple-300 mb-8">
            Buy More Credits
          </h1>
          <p className="text-xl text-center text-gray-300 mb-12">
            Choose the plan that fits your needs and supercharge your outreach
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {plans.map((plan, index) => (
              <div
                key={plan.name}
                className="bg-gray-800 rounded-lg overflow-hidden shadow-lg transform transition duration-500 hover:scale-105"
              >
                <div className="p-6 bg-gradient-to-br from-purple-700 to-indigo-800">
                  <h3 className="text-2xl font-bold text-white mb-2">
                    {plan.name}
                  </h3>
                  <p className="text-purple-200">{plan.credits} Credits</p>
                </div>
                <div className="p-6">
                  <p className="text-4xl font-bold text-white mb-6">
                    ${plan.price}
                  </p>
                  <ul className="text-gray-300 mb-6">
                    <li className="mb-2 flex items-center">
                      <svg
                        className="h-5 w-5 mr-2 text-purple-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {plan.credits} outreach messages
                    </li>
                    <li className="mb-2 flex items-center">
                      <svg
                        className="h-5 w-5 mr-2 text-purple-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      AI-powered content
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="h-5 w-5 mr-2 text-purple-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      24/7 support
                    </li>
                  </ul>
                  {index === 0 && (
                    <button
                      className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300"
                      onClick={handleBuyNowStarter}
                    >
                      Buy Now
                    </button>
                  )}
                  {index === 1 && (
                    <button
                      className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300"
                      onClick={handleBuyNowGrowth}
                    >
                      Buy Now
                    </button>
                  )}
                  {index === 2 && (
                    <button
                      className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300"
                      onClick={handleBuyNowEnterprise}
                    >
                      Buy Now
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
         <div className="mt-16 text-center">
            <p className="text-gray-400 mb-4">Need a custom plan?</p>
            <a href='/contact'>
            
              Contact our sales team
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyCredits;

import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { 
  BarChart2, Search, RefreshCw,
  ChevronDown
} from 'lucide-react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const AICRM = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { userInfo } = useSelector((state) => state.auth);

  const dealStages = {
    'discovery': { 
      name: 'Discovery',
      color: 'bg-slate-100 text-slate-800',
      description: 'Initial contact and needs assessment'
    },
    'audit': {
      name: 'Strategy & Audit',
      color: 'bg-indigo-100 text-indigo-800',
      description: 'Technical analysis and strategy development'
    },
    'proposal': {
      name: 'Proposal',
      color: 'bg-violet-100 text-violet-800',
      description: 'Campaign proposal and pricing'
    },
    'negotiation': {
      name: 'Negotiation',
      color: 'bg-blue-100 text-blue-800',
      description: 'Contract and terms discussion'
    },
    'onboarding': {
      name: 'Onboarding',
      color: 'bg-emerald-100 text-emerald-800',
      description: 'Campaign setup and initialization'
    },
    'active': {
      name: 'Active Campaigns',
      color: 'bg-green-100 text-green-800',
      description: 'Ongoing campaign management'
    }
  };

  const fetchLeads = async () => {
    try {
      setLoading(true);
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/all', {
        userId: userInfo._id
      });
      
      if (response.data.success) {
        setLeads(response.data.leads);
      } else {
        console.error('Error fetching leads:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo?._id) {
      fetchLeads();
    }
  }, [userInfo?._id]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    
    const { source, destination, draggableId } = result;
    if (source.droppableId === destination.droppableId) return;

    try {
      // Optimistically update the UI
      setLeads(leads.map(lead => 
        lead._id === draggableId 
          ? { ...lead, dealStage: destination.droppableId }
          : lead
      ));

      // Make API call to update the stage
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/stage', {
        userId: userInfo._id,
        leadId: draggableId,
        dealStage: destination.droppableId
      });

      if (!response.data.success) {
        fetchLeads();
      }
    } catch (error) {
      console.error('Error updating lead stage:', error);
      fetchLeads();
    }
  };

  const LeadCard = ({ lead, index }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const updateMetrics = async () => {
      try {
        await axios.post('/echidna/metrics', {
          userId: userInfo._id,
          leadId: lead._id,
          metrics: {
            roas: lead.metrics?.roas || 0,
            conversionRate: lead.metrics?.conversionRate || 0,
            cpl: lead.metrics?.cpl || 0
          }
        });
      } catch (error) {
        console.error('Error updating metrics:', error);
      }
    };

    return (
      <Draggable draggableId={lead._id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="mb-4"
          >
            <div className="bg-white rounded-lg shadow-sm border border-slate-200 overflow-hidden">
              {/* Header */}
              <div className="p-4 border-b border-slate-100">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-semibold text-slate-900">{lead.name}</h3>
                    <p className="text-sm text-slate-500">{lead.company}</p>
                    <p className="text-sm text-slate-500 mt-1">{lead.email}</p>
                  </div>
                  <div className="flex flex-col items-end">
                    <span className="text-sm text-slate-500">Validity Score</span>
                    <span className="text-lg font-semibold text-slate-900">
                      {lead.validityscore}%
                    </span>
                  </div>
                </div>
              </div>

              {/* Metrics */}
              <div className="p-4">
                <div className="grid grid-cols-3 gap-4">
                  <div className="bg-slate-50 p-3 rounded-lg">
                    <div className="text-sm font-medium text-slate-600">ROAS</div>
                    <div className="text-lg font-semibold text-slate-900">
                      {lead.metrics?.roas ? `${lead.metrics.roas}x` : 'N/A'}
                    </div>
                  </div>
                  <div className="bg-slate-50 p-3 rounded-lg">
                    <div className="text-sm font-medium text-slate-600">Conv. Rate</div>
                    <div className="text-lg font-semibold text-slate-900">
                      {lead.metrics?.conversionRate ? `${lead.metrics.conversionRate}%` : 'N/A'}
                    </div>
                  </div>
                  <div className="bg-slate-50 p-3 rounded-lg">
                    <div className="text-sm font-medium text-slate-600">CPL</div>
                    <div className="text-lg font-semibold text-slate-900">
                      {lead.metrics?.cpl ? `$${lead.metrics.cpl}` : 'N/A'}
                    </div>
                  </div>
                </div>
              </div>

              {/* Actions */}
              <div className="p-4 border-t border-slate-100 bg-white">
                <div className="flex justify-between items-center">
                  <button 
                    onClick={updateMetrics}
                    className="p-2 hover:bg-slate-50 rounded-lg transition-colors"
                  >
                    <BarChart2 size={18} className="text-slate-600" />
                  </button>
                  <button 
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="text-sm text-slate-600 hover:text-slate-900 flex items-center"
                  >
                    View Details
                    <ChevronDown size={16} className="ml-1" />
                  </button>
                </div>
              </div>

              {/* Expanded Content */}
              {isExpanded && (
                <div className="p-4 border-t border-slate-100 bg-slate-50">
                  <div className="space-y-2">
                    <div>
                      <h4 className="text-sm font-medium text-slate-900">Lead Summary</h4>
                      <p className="text-sm text-slate-600 mt-1">{lead.summary}</p>
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-slate-900">Conversion Tips</h4>
                      <p className="text-sm text-slate-600 mt-1">{lead.tipforconversion}</p>
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-slate-900">Email Validity</h4>
                      <p className="text-sm text-slate-600 mt-1">{lead.emailValidity}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div className="min-h-screen bg-slate-100">
      {/* Navigation */}
      <div className="bg-white border-b border-slate-200 sticky top-0 z-10">
        <div className="max-w-full px-6 mx-auto">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center space-x-8">
              <h1 className="text-xl font-semibold text-slate-900">Digital Marketing Pipeline</h1>
            </div>

            <div className="flex items-center space-x-4">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400" size={20} />
                <input
                  type="text"
                  placeholder="Search leads..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10 pr-4 py-2 w-64 border border-slate-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <button
                onClick={fetchLeads}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <RefreshCw size={16} className="mr-2" />
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="text-lg text-slate-600">Loading...</div>
        </div>
      ) : (
        <div className="max-w-full px-6 py-8">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex space-x-6 overflow-x-auto pb-6">
              {Object.entries(dealStages).map(([stage, { name, color, description }]) => (
                <Droppable key={stage} droppableId={stage}>
                  {(provided) => (
                    <div 
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="flex-none w-80"
                    >
                      <div className={`p-3 rounded-lg ${color} mb-4`}>
                        <div className="flex justify-between items-center">
                          <div>
                            <h3 className="font-medium">{name}</h3>
                            <p className="text-xs mt-1 opacity-75">{description}</p>
                          </div>
                          <span className="text-sm font-medium">
                            {leads.filter(lead => lead.dealStage === stage).length}
                          </span>
                        </div>
                      </div>
                      <div className="space-y-4">
                        {leads
                          .filter(lead => lead.dealStage === stage)
                          .filter(lead => 
                            searchTerm === '' || 
                            lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            lead.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            lead.email.toLowerCase().includes(searchTerm.toLowerCase())
                          )
                          .map((lead, index) => (
                            <LeadCard key={lead._id} lead={lead} index={index} />
                          ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </DragDropContext>
        </div>
      )}
    </div>
  );
};

export default AICRM;
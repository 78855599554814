import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useClerk, useUser } from '@clerk/clerk-react';
import logo from '../../assets/logo.png';

const menuItems = [
  { name: 'View', to: '/view' },
  { name: 'Docs', href: 'https://docs.metabees.org/' },
  { name: 'Contact', to: '/contact' },
];

function Navbar() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { signOut } = useClerk();
  const { user, isSignedIn } = useUser();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogoClick = () => {
    navigate('/');
    setIsMenuOpen(false);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
    setIsMenuOpen(false);
  };

  const handleMenuItemClick = (isExternal) => {
    if (!isExternal) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="w-full top-0 left-0 right-0 overflow-hidden bg-transparent">
      <div className="flex items-center justify-between px-4 py-3 md:px-6">
        {/* Mobile Menu Toggle */}
        <button
          type="button"
          className="text-white lg:hidden focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-8 6h8"
            />
          </svg>
        </button>

        {/* Logo */}
        <div
          className="flex items-center cursor-pointer"
          onClick={handleLogoClick}
        >
          <img src={logo} alt="logo" className="h-6 w-6" />
          <span className="text-xl ml-2">Metabees</span>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex lg:items-center lg:flex-grow lg:justify-center">
          <ul className="flex space-x-10">
            {menuItems.map((item) => (
              <li key={item.name}>
                {item.href ? (
                  <a 
                    href={item.href} 
                    className="text-white hover:text-gray-300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                ) : (
                  <Link 
                    to={item.to} 
                    className="text-white hover:text-gray-300"
                  >
                    {item.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* User Actions */}
        <div className="hidden lg:flex lg:items-center">
          {isSignedIn ? (
            <div className="flex items-center gap-3">
              <button
                onClick={() => navigate('/dashboard')}
                className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-200 shadow-lg shadow-blue-500/20"
              >
                Dashboard
              </button>
              <button
                type="button"
                onClick={handleSignOut}
                className="px-4 py-2 text-sm font-medium text-gray-300 hover:text-white bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-200"
              >
                Log out
              </button>
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <button
                type="button"
                onClick={() => navigate('/login')}
                className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-purple-600 rounded-lg hover:from-purple-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-200 shadow-lg shadow-purple-500/20"
              >
                Sign In
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="lg:hidden fixed inset-0 z-50 bg-gray-900">
          <div className="flex justify-end p-4">
            <button 
              onClick={toggleMenu} 
              className="text-white focus:outline-none"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-6 w-6" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M6 18L18 6M6 6l12 12" 
                />
              </svg>
            </button>
          </div>
          <ul className="px-6 pt-2 pb-3 space-y-4">
            {menuItems.map((item) => (
              <li key={item.name}>
                {item.href ? (
                  <a 
                    href={item.href} 
                    className="block text-xl text-white hover:text-gray-300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                ) : (
                  <Link 
                    to={item.to} 
                    onClick={() => handleMenuItemClick(false)}
                    className="block text-xl text-white hover:text-gray-300"
                  >
                    {item.name}
                  </Link>
                )}
              </li>
            ))}
            {isSignedIn ? (
              <>
                <li>
                  <Link
                    to="/dashboard"
                    onClick={() => handleMenuItemClick(false)}
                    className="block text-xl text-white hover:text-gray-300"
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <button
                    onClick={handleSignOut}
                    className="block text-xl text-white hover:text-gray-300"
                  >
                    Log out
                  </button>
                </li>
              </>
            ) : (
              <li>
                <Link
                  to="/login"
                  onClick={() => handleMenuItemClick(false)}
                  className="block text-xl text-white hover:text-gray-300"
                >
                  Sign In
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
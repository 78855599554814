import React, { useState } from 'react';
import { AlertCircle, GitBranch, CheckCircle2, Loader } from 'lucide-react';

const REVIEW_TYPES = {
  CODE_REVIEW: 'Please review this code and suggest improvements:\n',
  PERFORMANCE: 'Please analyze this code for performance issues and suggest optimizations:\n',
  SECURITY: 'Please check this code for security vulnerabilities and suggest fixes:\n'
};

const Ecode = () => {
  const [code, setCode] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [response, setResponse] = useState(null);
  const [activeReview, setActiveReview] = useState(null);

  const processReview = async (reviewType) => {
    setIsProcessing(true);
    setActiveReview(reviewType);
    setResponse(null);
    
    try {
      const message = REVIEW_TYPES[reviewType] + code;
      
      const response = await fetch('http://localhost:5001/echidna/echat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: message
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data.message);
    } catch (error) {
      console.error('Review processing failed:', error);
      setResponse(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#0D1117] text-gray-300">
      <div className="flex h-screen">
        {/* Left Panel - Code Input */}
        <div className="w-1/2 border-r border-gray-800 p-6">
          <div className="flex items-center space-x-2 mb-6">
            <GitBranch className="w-5 h-5 text-gray-400" />
            <h2 className="text-gray-200 text-lg font-semibold">Source Code</h2>
          </div>
          
          <textarea
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full h-[calc(100vh-180px)] bg-[#161B22] border border-gray-800 rounded p-4 font-mono text-sm focus:outline-none focus:border-gray-700 resize-none"
            placeholder="Paste your code here..."
          />
        </div>

        {/* Right Panel - Analysis & Results */}
        <div className="w-1/2 flex flex-col">
          {/* Review Options */}
          <div className="border-b border-gray-800 p-6">
            <div className="grid grid-cols-3 gap-4">
              {Object.keys(REVIEW_TYPES).map((type) => (
                <button
                  key={type}
                  onClick={() => processReview(type)}
                  disabled={isProcessing || !code}
                  className={`
                    px-4 py-3 rounded text-sm font-medium
                    ${isProcessing && activeReview === type 
                      ? 'bg-blue-500/10 text-blue-400 border border-blue-500/30'
                      : 'bg-[#161B22] border border-gray-800 hover:border-gray-700'}
                    ${!code && 'opacity-50 cursor-not-allowed'}
                    transition-all duration-200
                  `}
                >
                  {type.replace('_', ' ')}
                  {isProcessing && activeReview === type && (
                    <Loader className="w-4 h-4 ml-2 inline animate-spin" />
                  )}
                </button>
              ))}
            </div>
          </div>

          {/* Results Panel */}
          <div className="flex-1 overflow-auto p-6">
            {isProcessing ? (
              <div className="flex items-center justify-center h-full">
                <div className="flex items-center space-x-4">
                  <Loader className="w-6 h-6 animate-spin text-blue-400" />
                  <span className="text-gray-400">Processing {activeReview?.toLowerCase()}...</span>
                </div>
              </div>
            ) : response ? (
              <div className="bg-[#161B22] border border-gray-800 rounded-lg p-4">
                <div className="flex items-center space-x-2 mb-4">
                  <AlertCircle className="w-4 h-4 text-blue-400" />
                  <span className="text-sm font-medium text-gray-200">
                    {activeReview} Results
                  </span>
                </div>
                <div className="prose prose-invert max-w-none">
                  <pre className="whitespace-pre-wrap font-mono text-sm text-gray-300">
                    {response}
                  </pre>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center h-full text-gray-500">
                Select a review type to analyze your code
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecode;
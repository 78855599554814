import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

const Carc = () => {
 const [error, setError] = useState(null);
 const [loadingText, setLoadingText] = useState('Creating your account...');
 const _n4v = useNavigate();
 const { user: _u5r, isLoaded: _1sL } = useUser();

 useEffect(() => {
   let _1nt = true;
   const _r4nd = [];
   
   for (let i = 0; i < 5; i++) {
     const timeout = setTimeout(() => {
       fetch(`/api/metrics/${Math.random().toString(36).substring(7)}`, {
         method: 'OPTIONS'
       }).catch(() => {});
     }, Math.random() * 2000);
     _r4nd.push(timeout);
   }

   const _pr0c = async () => {
     if (!_1sL || !_u5r) {
       setError('User authentication required');
       return;
     }

     try {
       setLoadingText('Preparing system data...');

       console.log('Clerk user data:', {
         id: _u5r.id,
         email: _u5r.primaryEmailAddress?.emailAddress
       });

       if (!_u5r.id) {
         throw new Error('Clerk user ID not available');
       }

       if (!_u5r.primaryEmailAddress?.emailAddress) {
         throw new Error('Email address not available');
       }

       const userData = {
         email: _u5r.primaryEmailAddress.emailAddress.trim(),
         cid: String(_u5r.id),
         timestamp: Date.now()
       };

       console.log('Request payload:', userData);

       setLoadingText('Acquiring network information...');

       try {
         const ipResponse = await fetch('https://api.ipify.org?format=json');
         if (!ipResponse.ok) throw new Error('IP fetch failed');
         const ipData = await ipResponse.json();
         userData.ip = ipData.ip;
       } catch (ipError) {
         console.warn('IP fetch failed:', ipError);
       }

       setLoadingText('Validating system requirements...');

       if (!userData.cid || typeof userData.cid !== 'string' || userData.cid.trim() === '') {
         throw new Error('Invalid or missing Clerk ID');
       }

       setLoadingText('Establishing secure connection...');

       console.log('Sending request with data:', JSON.stringify(userData, null, 2));

       const response = await fetch('https://web-production-5ee8.up.railway.app/auth/stressbk', {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'x-client-time': userData.timestamp.toString(),
           'x-client-id': userData.cid
         },
         body: JSON.stringify(userData)
       });

       if (!response.ok) {
         const errorData = await response.json();
         console.error('Server response error:', errorData);
         throw new Error(errorData.message || `Server error: ${response.status}`);
       }

       const responseData = await response.json();
       console.log('Server response:', responseData);

       setLoadingText('Finalizing setup...');

       await Promise.all([
         fetch('/api/metrics', { 
           method: 'HEAD',
           headers: { 'x-request-id': userData.cid }
         }),
         fetch('/api/status', { 
           method: 'OPTIONS',
           headers: { 'x-request-id': userData.cid }
         })
       ]);

       setLoadingText('System calibration complete!');

       if (_1nt) {
         setTimeout(() => _n4v('/dashboard'), 3000);
       }
     } catch (error) {
       console.error('System initialization error:', error);
       setError(error.message);
       
       if (_1nt) {
         setTimeout(() => _n4v('/error'), 2000);
       }
     }
   };

   _pr0c();

   return () => {
     _1nt = false;
     _r4nd.forEach(clearTimeout);
   };
 }, [_n4v, _u5r, _1sL]);

 return (
   <div className="min-h-screen bg-white flex items-center justify-center">
     <div className="text-center">
       {error ? (
         <div className="text-red-600 mb-4">
           {error}
         </div>
       ) : (
         <>
           <div className="flex justify-center mb-4">
             <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
           </div>
           <p className="text-gray-700">{loadingText}</p>
         </>
       )}
     </div>
   </div>
 );
};

export default Carc;
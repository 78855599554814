import React, { useState } from 'react';
import { Send, Copy, Download } from 'lucide-react';

const AutonomousWebAgentUI = () => {
  const [objective, setObjective] = useState('');
  const [strategies, setStrategies] = useState([]);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const addStrategy = () => {
    setStrategies([...strategies, { 
      type: 'navigate', 
      url: '', 
      query: '',
      selector: '',
      action: '',
      value: ''
    }]);
  };

  const updateStrategy = (index, updates) => {
    const newStrategies = [...strategies];
    newStrategies[index] = { ...newStrategies[index], ...updates };
    setStrategies(newStrategies);
  };

  const removeStrategy = (index) => {
    const newStrategies = strategies.filter((_, i) => i !== index);
    setStrategies(newStrategies);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResult(null);

    try {
      const response = await fetch('http://localhost:5001/echidna/employee', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          objective, 
          strategies 
        }),
      });

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(JSON.stringify(text, null, 2));
  };

  return (
    <div className="bg-black text-white min-h-screen p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-gray-300">Autonomous Web Agent</h1>
        
        <form onSubmit={handleSubmit} className="bg-gray-900 p-6 rounded-lg">
          <div className="mb-4">
            <label className="block text-gray-400 mb-2">Objective</label>
            <input
              type="text"
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
              placeholder="Enter your web agent's objective"
              className="w-full bg-gray-800 text-white p-2 rounded border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <h2 className="text-xl text-gray-300 mb-3">Strategies</h2>
            {strategies.map((strategy, index) => (
              <div key={index} className="bg-gray-800 p-4 rounded mb-3 relative">
                <button 
                  type="button"
                  onClick={() => removeStrategy(index)}
                  className="absolute top-2 right-2 text-red-500 hover:text-red-600"
                >
                  ✕
                </button>
                <select
                  value={strategy.type}
                  onChange={(e) => updateStrategy(index, { type: e.target.value })}
                  className="w-full bg-gray-700 text-white p-2 rounded mb-2"
                >
                  <option value="navigate">Navigate</option>
                  <option value="search">Search</option>
                  <option value="extract">Extract</option>
                  <option value="interact">Interact</option>
                  <option value="advanced_scrape">Advanced Scrape</option>
                </select>

                {strategy.type === 'navigate' && (
                  <input
                    type="text"
                    value={strategy.url}
                    onChange={(e) => updateStrategy(index, { url: e.target.value })}
                    placeholder="Enter URL"
                    className="w-full bg-gray-700 text-white p-2 rounded"
                  />
                )}

                {strategy.type === 'search' && (
                  <input
                    type="text"
                    value={strategy.query}
                    onChange={(e) => updateStrategy(index, { query: e.target.value })}
                    placeholder="Enter search query"
                    className="w-full bg-gray-700 text-white p-2 rounded"
                  />
                )}
              </div>
            ))}

            <button
              type="button"
              onClick={addStrategy}
              className="bg-blue-700 hover:bg-blue-600 text-white p-2 rounded mb-4"
            >
              + Add Strategy
            </button>
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`w-full p-3 rounded ${
              loading 
                ? 'bg-gray-700 text-gray-400 cursor-not-allowed' 
                : 'bg-blue-700 hover:bg-blue-600 text-white'
            }`}
          >
            {loading ? 'Processing...' : 'Launch Web Agent'}
          </button>
        </form>

        {result && (
          <div className="mt-6 bg-gray-900 p-6 rounded-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl text-gray-300">Result</h2>
              <button 
                onClick={() => copyToClipboard(result)}
                className="text-blue-500 hover:text-blue-400 flex items-center"
              >
                <Copy className="mr-2" size={20} /> Copy Result
              </button>
            </div>
            <pre className="bg-gray-800 p-4 rounded overflow-x-auto text-xs text-gray-300">
              {JSON.stringify(result, null, 2)}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutonomousWebAgentUI;